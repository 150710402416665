import { Divider, Layout } from "antd";
import {
  FC,
  useState,
  PropsWithChildren,
  useCallback,
  useRef,
  useEffect,
  UIEventHandler,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { DashboardLayoutFooter } from "./DashboardFooter";
import { DashboardLayoutMainMenu } from "./Menu";
import { DashboardLayoutHeaderLarge } from "./HeaderLarge";

type Props = {
  onActionClick: (action: string) => void;
  collapsed: boolean;
};

const { Sider, Header, Content, Footer } = Layout;
export const DashboardLargeLayout: FC<PropsWithChildren<Props>> = ({
  children,
  onActionClick,
  collapsed,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerElevated, setHeaderElevated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const collapseAction = useCallback(
    () => onActionClick("menu"),
    [onActionClick]
  );
  const scrollCallback = useCallback<UIEventHandler<HTMLDivElement>>(
    (e) => {
      // const target = e?.currentTarget || contentRef.current;
      // const totalHeight = window.innerHeight;
      // const headerHeight = headerRef.current?.clientHeight || 0;
      // const scrollHeight = target.scrollHeight;
      // const scrollOffset = target.scrollTop;
      // if (scrollHeight <= totalHeight - headerHeight + 50) {
      //   if (headerElevated) setHeaderElevated(false);
      // } else {
      //   if (scrollOffset > 5) {
      //     setHeaderElevated(true);
      //   } else {
      //     setHeaderElevated(false);
      //   }
      // }
    },
    [setHeaderElevated, headerRef, contentRef]
  );

  useEffect(() => {
    setTimeout(scrollCallback, 500);
  }, [location, scrollCallback]);

  return (
    <Layout className={"w-screen h-screen overflow-hidden"}>
      <Layout className="overflow-x-hidden h-screen overflow-y-auto">
        <Header
          className={
            " bg-opacity-30 bg-white backdrop-blur-md sticky top-0 border-0 border-b border-solid border-neutral-200 z-50"
          }
        >
          <DashboardLayoutHeaderLarge onActionClick={onActionClick} />
        </Header>
        <Layout>
          <Sider
            collapsible
            collapsed={collapsed}
            collapsedWidth={65}
            width={200}
            trigger={null}
            className="relative"
          >
            <div
              className="fixed place-items-start text-start left-0 h-full border-0 border-r border-solid border-neutral-200"
              style={{
                width: collapsed ? "65px" : "200px",
              }}
            >
              <DashboardLayoutMainMenu />
              <div className="absolute z-40 right-0 top-5 translate-x-1/2">
                <FontAwesomeIcon
                  className="p-2 bg-white rounded-full border border-neutral-200 border-solid cursor-pointer text-neutral-500"
                  onClick={collapseAction}
                  size="1x"
                  icon={collapsed ? faChevronRight : faClose}
                />
              </div>
            </div>
          </Sider>
          <Content className="flex flex-col">
            <div className="flex-1">{children}</div>
            <Footer>
              <Divider />
              <DashboardLayoutFooter />
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
