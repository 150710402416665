import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Tree, TreeDataNode, TreeProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faRefresh,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { useFleetDashboardPageViewModel } from "./ViewModel";
import { MultipleGatewayMap } from "../../../core/presentation/component/GatewayMap";
import { Key } from "antd/es/table/interface";
import { Gateway } from "../../gateway/domain/type/Gateway";
import { groupBy } from "lodash";
import { VehicleChart } from "../../gateway/domain/type/VehicleChart";
import { DashboardPageVehicleChartGroup } from "./components/DashboardGroup";

export const FleetDashboardPage: FC = () => {
  const [treeCheckedKeys, setTreeCheckedKeys] = useState<string[]>([]);
  const {
    fetchChartList,
    fetchChartListState,
    charts,
    chartGroups,
    onFetchChartListStateReceived,
  } = useFleetDashboardPageViewModel();

  useEffect(() => {
    fetchChartList();
  }, []);

  const treeData = useMemo<TreeDataNode[]>(() => {
    const tree: TreeDataNode[] = [];
    chartGroups.forEach((group) => {
      const nodes: TreeDataNode[] = [];
      group.charts.forEach((chart) => {
        nodes.push({
          key: `${chart.id}`,
          title: chart.ChartDefinition.name,
          icon: <FontAwesomeIcon icon={faServer} />,
        });
      });
      tree.push({
        key: `vehicle-${group.vehicle.id}`,
        title: group.vehicle.name,
        icon: <FontAwesomeIcon icon={faLocation} />,
        children: nodes,
      });
    });
    return tree;
  }, [chartGroups]);

  const onTreeCheck: TreeProps["onCheck"] = useCallback((checkedKeys: any) => {
    setTreeCheckedKeys(checkedKeys);
  }, []);

  const selectedCharts = useMemo<Array<Array<VehicleChart>>>(() => {
    const selected = charts.filter((chart) =>
      treeCheckedKeys.includes(`${chart.id}`)
    );
    const indexes = new Map<number, number>();
    return selected.reduce(
      (initial: Array<Array<VehicleChart>>, value, index) => {
        const position = indexes.get(value.vehicleId) ?? indexes.size;
        if (!indexes.has(value.vehicleId)) {
          indexes.set(value.vehicleId, position);
          initial.push([value]);
        } else {
          initial[position].push(value);
        }
        return initial;
      },
      []
    );
  }, [treeCheckedKeys, charts]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={fetchChartListState?.loading ?? false} />
      <CustomBreadCrumb
        items={[
          {
            pathname: "/dashboard/charts",
            title: "Charts",
            active: true,
            icon: <FontAwesomeIcon icon={faLocation} />,
          },
        ]}
      />

      <ContentHeader
        title={"Localización de Flota"}
        subtitle={`Visualiza la localización de tus unidades`}
        extra={
          <Button.Group>
            <Button
              onClick={fetchChartList}
              type={"primary"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      <Card size="small" title={"Seleccionar flota"}>
        <Tree
          checkable
          autoExpandParent
          treeData={treeData}
          onCheck={onTreeCheck}
          checkedKeys={treeCheckedKeys}
        />
      </Card>
      <div className="w-full relative overflow-x-hidden overflow-y-auto">
        {selectedCharts.map((group, index) => (
          <DashboardPageVehicleChartGroup
            key={`group-d-v-${index}`}
            group={group}
          />
        ))}
      </div>
    </ResponsiveContainer>
  );
};
