import { Col, Row, Typography, Flex, Card } from "antd";
import { FC } from "react";
import { TotalUsersCountCard } from "./TotalUsers";
import { ActiveUsersCounterCard } from "./ActiveUsers";

type Props = {};

export const OverviewUsersSection: FC<Props> = ({}) => {
  return (
    <Flex vertical>
      <Typography.Title level={3}>Usuarios</Typography.Title>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Card bordered={false}>
            <TotalUsersCountCard count={24} loading={false} />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Card bordered={false}>
            <ActiveUsersCounterCard count={24} loading={false} />
          </Card>
        </Col>
      </Row>
    </Flex>
  );
};
