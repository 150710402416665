import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  Skeleton,
  Typography,
  notification,
} from "antd";
import stringToColor from "string-to-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faLocation,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import { Gateway, GatewayInfo } from "../../../../gateway/domain/type/Gateway";
import { GatewayLocation } from "../../../../../core/domain/type/DeviceData";
import { useUnitDescriptionControlViewModel } from "../../../../../core/presentation/component/units_map/viewmodel/UnitDescriptionControl";
import { SocketIOService } from "../../../../../app/service/SocketIO";
import { ImageHOC } from "../../../../../core/presentation/component/Image";

type Props = {
  gateway?: Gateway | null;
  onClose?: () => void;
};

export const OverviewUnitInfo: FC<Props> = ({ gateway, onClose }) => {
  const lastOnlineTime = useRef<number>(0);
  const lastAddressFetch = useRef<number>(0);
  const lastGatewayId = useRef<number>(-1);
  const [gatewayInfo, setGatewayInfo] = useState<GatewayInfo | null>(null);
  const [gatewayLocation, setGatewayLocation] =
    useState<GatewayLocation | null>(null);
  const {
    address,
    fetchAddressState,
    fetchCoordinatesAddress,
    fetchGatewayInfo,
    fetchGatewayInfoState,
    onFetchAddressStateReceived,
    onFetchGatewayInfoStateReceived,
  } = useUnitDescriptionControlViewModel();

  const locationListener = useCallback(
    (data: GatewayLocation) => {
      setGatewayLocation(data);
      lastOnlineTime.current = Date.now();
    },
    [setGatewayLocation, lastOnlineTime]
  );

  const gatewayInfoListener = useCallback(
    (data: GatewayInfo) => {
      setGatewayInfo(data);
      lastOnlineTime.current = Date.now();
    },
    [setGatewayInfo, lastOnlineTime]
  );

  useEffect(() => {
    const gatewayCopy = gateway;
    if (!!gatewayCopy) {
      SocketIOService.socketOn(
        `data/${gatewayCopy.key}/gps_live`,
        locationListener
      );
    }
    return () => {
      if (!!gatewayCopy) {
        SocketIOService.socketOff(
          `data/${gatewayCopy.key}/gps_live`,
          locationListener
        );
      }
    };
  }, [gateway, locationListener]);

  useEffect(() => {
    const gatewayCopy = gateway;
    if (!!gatewayCopy) {
      SocketIOService.socketOn(
        `data/${gatewayCopy.key}/gateway_info`,
        gatewayInfoListener
      );
    }
    return () => {
      if (!!gatewayCopy) {
        SocketIOService.socketOff(
          `data/${gatewayCopy.key}/gateway_info`,
          gatewayInfoListener
        );
      }
    };
  }, [gateway, gatewayInfoListener]);

  useEffect(() => {
    if (!!gateway) {
      if (lastGatewayId.current !== gateway.id) {
        setGatewayInfo(null);
        setGatewayLocation(null);
        lastOnlineTime.current = 0;
      }
      void fetchGatewayInfo(gateway.id);
    }
  }, [gateway]);

  useEffect(() => {
    if (!!fetchGatewayInfoState && !fetchGatewayInfoState.loading) {
      if (fetchGatewayInfoState.hasError) {
        notification.error({
          message: "Error al obtener información del gateway.",
          description: fetchGatewayInfoState.error?.message,
        });
      } else {
        setGatewayInfo(fetchGatewayInfoState.data!!);
      }
      onFetchGatewayInfoStateReceived();
    }
  }, [fetchGatewayInfoState]);

  useEffect(() => {
    if (!!fetchAddressState && !fetchAddressState.loading) {
      if (fetchAddressState.hasError) {
        notification.error({
          message: "Error al obtener la dirección.",
          description: fetchAddressState.error?.message,
        });
      }
      onFetchAddressStateReceived();
    }
  }, [fetchAddressState]);

  useEffect(() => {
    if (!!gatewayLocation && Date.now() - lastAddressFetch.current > 1000 * 5) {
      fetchCoordinatesAddress(gatewayLocation!);
      lastAddressFetch.current = Date.now();
    }
  }, [gatewayLocation]);

  const bandColor = useMemo(() => {
    return !!gateway ? stringToColor(gateway.key) : "transparent";
  }, [gateway]);

  if (!gateway) return null;
  return (
    <div className={"leaflet-bottom leaflet-left w-3/4"}>
      <div className="leaflet-control w-full">
        <Card className="relative">
          <Button.Group className={"absolute top-0 right-0 m-2"}>
            <Button
              onClick={onClose}
              shape="circle"
              type="primary"
              icon={<FontAwesomeIcon icon={faClose} />}
            />
          </Button.Group>

          <Card.Meta
            title={
              <div className="flex flex-row place-items-center gap-2">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: bandColor,
                  }}
                />
                {gateway.Vehicle?.name}{" "}
              </div>
            }
            description={
              <div className="w-full flex flex-col">
                <Skeleton
                  loading={fetchGatewayInfoState?.loading}
                  paragraph={false}
                >
                  <Descriptions>
                    <Descriptions.Item label={"Gateway"}>
                      <Typography.Text>{gateway.key}</Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={"Señal"}>
                      <Typography.Text>
                        {gatewayInfo?.ping_acme ?? "-"}
                      </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={"Estado"}>
                      {Date.now() - lastOnlineTime.current < 1000 * 60 * 5 ? (
                        <Typography.Text type="success">
                          En línea
                        </Typography.Text>
                      ) : (
                        <Typography.Text type="danger">
                          Desconectado
                        </Typography.Text>
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
                <Descriptions>
                  <Descriptions.Item label={"Lat."}>
                    <Typography.Text>
                      {gatewayLocation?.latitude}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Long."}>
                    <Typography.Text>
                      {gatewayLocation?.longitude}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Velocidad"}>
                    <Typography.Text>
                      {gatewayLocation?.speed
                        ? `${gatewayLocation.speed.toPrecision(2)} km/h`
                        : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                </Descriptions>
                <Skeleton loading={fetchAddressState?.loading}>
                  <Descriptions>
                    <Descriptions.Item label={"Ubicación"}>
                      <Typography.Text>
                        {fetchAddressState?.loading
                          ? "Cargando..."
                          : fetchAddressState?.hasError
                          ? "Error al obtener la dirección"
                          : address}
                      </Typography.Text>
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
              </div>
            }
            avatar={
              <div className="w-24 h-24 rounded overflow-hidden">
                <ImageHOC
                  allowZooming
                  imgProps={{
                    src: gateway.Vehicle?.photo,
                    className: "w-24 h-24 object-cover",
                  }}
                  errorComponent={
                    <div className="bg-primary w-full h-full flex place-content-center place-items-center text-white">
                      <FontAwesomeIcon icon={faTruck} size="2xl" />
                    </div>
                  }
                />
              </div>
            }
          />
        </Card>
      </div>
    </div>
  );
};
