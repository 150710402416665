import { useState } from "react";
import { AppTaskStateG } from "../../../../app/domain/model/State";
import { CustomerRepository } from "../../../../features/customer/data/repository/Customer";
import { CustomerUser } from "../../../../features/customer_user/domain/type/CustomerUser";
import { AuthRepository } from "../../../../features/auth/data/repository/Auth";
import { Customer } from "../../../../features/customer/domain/type/Customer";
import { AppTaskState } from "../../../../app/domain/type/State";
import { DashboardAllowedRoutes } from "../domain/type/AllowedRoutes";
import { DashboardRepository } from "../data/repository/Dashboard";
import { CustomerStyle } from "../../../../features/customer/domain/type/CustomerStyle";

export function useDashboardLayoutViewModel() {
  const [fetchCustomerState, setFetchCustomerState] =
    useState<AppTaskState<Customer> | null>(null);
  const [fetchCustomerStyleState, setFetchCustomerStyleState] =
    useState<AppTaskState<CustomerStyle> | null>(null);
  const [fetchCurrentUserState, setFetchCurrentUserState] =
    useState<AppTaskState<CustomerUser> | null>(null);
  const [fetchAllowedRoutesState, setFetchAllowedRoutesState] =
    useState<AppTaskState<DashboardAllowedRoutes> | null>(null);
  async function fetchCustomer() {
    if (fetchCustomerState?.loading) return;
    setFetchCustomerState(AppTaskStateG.loading());
    try {
      const customer = await CustomerRepository.getCustomer();
      if (customer.ok) {
        setFetchCustomerState(AppTaskStateG.success(customer.data!!));
      } else {
        setFetchCustomerState(
          AppTaskStateG.error(new Error(customer.message!!))
        );
      }
    } catch (e: any) {
      setFetchCustomerState(AppTaskStateG.error(e));
    }
  }

  function onFetchCustomerStateReceived() {
    setFetchCustomerState(null);
  }

  async function fetchCurrentUser() {
    if (fetchCurrentUserState?.loading) return;
    setFetchCurrentUserState(AppTaskStateG.loading());
    try {
      const session = await AuthRepository.getCurrentUser();
      if (session?.ok) {
        setFetchCurrentUserState(AppTaskStateG.success(session.data!!));
      } else {
        setFetchCurrentUserState(
          AppTaskStateG.error(new Error(session?.message))
        );
      }
    } catch (e: any) {
      setFetchCurrentUserState(AppTaskStateG.error(e));
    }
  }

  function onFetchCurrentUserStateReceived() {
    setFetchCurrentUserState(null);
  }

  async function fetchAllowedRoutes() {
    if (fetchAllowedRoutesState?.loading) return;
    setFetchAllowedRoutesState(AppTaskStateG.loading());
    try {
      const routes = await DashboardRepository.getAllowedRoutes();
      if (routes?.ok) {
        setFetchAllowedRoutesState(AppTaskStateG.success(routes!!.data!!));
      } else {
        setFetchAllowedRoutesState(
          AppTaskStateG.error(new Error(routes?.message!!))
        );
      }
    } catch (e: any) {
      setFetchAllowedRoutesState(AppTaskStateG.error(e));
    }
  }

  function onFetchAllowedRoutesStateReceived() {
    setFetchAllowedRoutesState(null);
  }

  async function fetchCustomerStyle() {
    if (fetchCustomerStyleState?.loading) return;
    setFetchCustomerStyleState(AppTaskStateG.loading());
    try {
      const style = await CustomerRepository.getCustomerStyle();
      if (style.ok) {
        setFetchCustomerStyleState(AppTaskStateG.success(style.data!!));
      } else {
        setFetchCustomerStyleState(
          AppTaskStateG.error(new Error(style.message!!))
        );
      }
    } catch (e: any) {
      setFetchCustomerStyleState(AppTaskStateG.error(e));
    }
  }

  function onFetchCustomerStyleStateReceived() {
    setFetchCustomerStyleState(null);
  }

  return {
    fetchCustomerState,
    fetchCustomer,
    onFetchCustomerStateReceived,
    fetchCurrentUser,
    fetchCurrentUserState,
    onFetchCurrentUserStateReceived,
    fetchAllowedRoutes,
    onFetchAllowedRoutesStateReceived,
    fetchAllowedRoutesState,
    fetchCustomerStyleState,
    onFetchCustomerStyleStateReceived,
    fetchCustomerStyle,
  };
}
