import { DashboardPathsType } from "../type/Paths";

export const DashboardPaths: DashboardPathsType = {
  dashboard: "/dashboard",
  overview: "/dashboard/overview",
  fleet_list: "/dashboard/fleet",
  unit_p_id: "/dashboard/fleet/:id",
  gateway_list: "/dashboard/gateways",
  gateway_p_id: "/dashboard/gateways/:id",
  customer: "/dashboard/customer",
  user_list: "/dashboard/users",
  user_p_id: "/dashboard/users/:id",
  fleet_tracking: "/dashboard/fleet_tracking",
  fleet_dashboard: "/dashboard/fleet_dashboard",
  fleet_reports: "/dashboard/fleet/reports",
  fleet_historical: "/dashboard/fleet/historical",
};
