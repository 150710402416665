import { Avatar, Card, Modal, Radio, Typography } from "antd";
import { FC, Fragment, useCallback, useMemo, useState } from "react";
import { Gateway } from "../../../../../../gateway/domain/type/Gateway";
import { RadioChangeEvent } from "antd/lib";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";

type Props = {
  open: boolean;
  gatewayList: Array<Gateway>;
  onConfirm: (gateway: Gateway) => void;
  onCancel: () => void;
};

const Status = {
  empty: 0,
  single: 1,
  multiple: 2,
};

const GatewayCard: FC<{ gateway: Gateway }> = ({ gateway }) => {
  return (
    <Card size="small">
      <Card.Meta
        avatar={
          <Avatar size={"small"} icon={<FontAwesomeIcon icon={faServer} />} />
        }
        description={gateway.description}
        title={gateway.key}
      />
    </Card>
  );
};

export const TabVehicleModalScan: FC<Props> = ({
  open,
  gatewayList,
  onCancel,
  onConfirm,
}) => {
  const [selectedGatewayKey, setSelectedGatewayKey] = useState<string | null>(
    null
  );
  const allowedGateways = useMemo(
    () => gatewayList.filter((it) => it.allowScanning),
    [gatewayList]
  );
  const rejectedGateways = useMemo(
    () => gatewayList.filter((it) => !it.allowScanning),
    [gatewayList]
  );
  const status = useMemo(() => {
    if (allowedGateways.length === 0) return Status.empty;
    if (allowedGateways.length === 1) return Status.single;
    return Status.multiple;
  }, [allowedGateways]);
  const onOptionSelected = useCallback(
    (e: RadioChangeEvent) => {
      setSelectedGatewayKey(e.target.value);
    },
    [setSelectedGatewayKey]
  );

  const onOkPressed = useCallback(() => {
    if (status === Status.single) {
      onConfirm(allowedGateways[0]);
    } else if (status === Status.multiple) {
      if (selectedGatewayKey) {
        const gateway = gatewayList.find(
          (gateway) => gateway.key === selectedGatewayKey
        );
        if (gateway) {
          onConfirm(gateway);
        }
      }
    } else {
      onCancel();
    }
  }, [
    selectedGatewayKey,
    gatewayList,
    onConfirm,
    status,
    onCancel,
    allowedGateways,
  ]);

  return (
    <Modal
      open={open}
      title={"Confirmar Escaneo"}
      okText={status === Status.empty ? "Cerrar" : "Confirmar"}
      onOk={onOkPressed}
      onCancel={onCancel}
    >
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col gap-1">
          {status === Status.empty ? (
            <Typography.Text type="secondary">
              No hay gateways disponibles para escanear
            </Typography.Text>
          ) : status === Status.single ? (
            <Fragment>
              <Typography.Text type="secondary" className="leading-tight">
                Se seleccionó el único gateway disponible para escanear
              </Typography.Text>
              <GatewayCard gateway={allowedGateways[0]} />
            </Fragment>
          ) : (
            <Fragment>
              <Typography.Text type="secondary">
                Seleccione el gateway que se usará para escanear
              </Typography.Text>
              <Radio.Group
                onChange={onOptionSelected}
                value={selectedGatewayKey}
              >
                {allowedGateways.map((gateway) => {
                  return (
                    <Radio key={`radio-${gateway.key}`} value={gateway.key}>
                      <GatewayCard gateway={gateway} />
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Fragment>
          )}
        </div>
        {!isEmpty(rejectedGateways) && (
          <div className="flex flex-col gap-1">
            <Typography.Text type="secondary" className="leading-tight">
              Los siguientes gateways están disponibles pero no permiten escaneo
              de variables.
            </Typography.Text>
            {rejectedGateways.map((gateway) => {
              return (
                <GatewayCard
                  gateway={gateway}
                  key={`rejected-${gateway.key}`}
                />
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};
