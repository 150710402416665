import { VehicleChartSelection } from "../../../features/gateway/domain/type/VehicleChart";
import { RemoteVehicleChartSource } from "../source/RemoteVehicleChart";

export class VehicleChartRepository {
  static async getVehicleCharts(
    vehicleId: number,
    selection?: VehicleChartSelection
  ) {
    return await RemoteVehicleChartSource.getVehicleCharts(
      vehicleId,
      selection
    );
  }

  static async getCustomerCharts(selection?: VehicleChartSelection) {
    return await RemoteVehicleChartSource.getCustomerCharts(selection);
  }

  static async scanVehicleCharts(gatewayId: number, vehicleId: number) {
    return await RemoteVehicleChartSource.scanVehicleCharts(
      gatewayId,
      vehicleId
    );
  }
}
