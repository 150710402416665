import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import * as echarts from "echarts/core";
import { cloneDeep, isNil } from "lodash";

type Schema = {
  title: string;
  subtitle: string;
  map: string;
  unit: string;
  min: number;
  max: number;
};

type Props = {
  data: Array<{
    name: string;
    value: number;
  }>;
  schema?: Schema;
  onSelected?: (name: string) => void;
};

export const WorldMapChart: FC<Props> = ({ data, schema, onSelected }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<echarts.ECharts | null>(null);
  const onRegionClicked = useCallback(
    (params: echarts.ECElementEvent) => {
      if (!!onSelected) {
        onSelected(params.name);
      }
    },
    [onSelected]
  );

  const baseOption = useMemo(
    () => ({
      title: {},
      legend: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: `{b}<br/>{c} ${schema?.unit}`,
      },
      toolbox: {
        show: false,
      },
      visualMap: {
        min: schema?.min || 0,
        max: schema?.max || 0,
        realtime: true,
        calculable: true,
      },
      series: [
        {
          name: schema?.title,
          type: "map",
          map: schema?.map,
          roam: true,
          label: {
            show: false,
          },
          data: [],

          itemStyle: {
            areaColor: "#323c48",
            borderColor: "#FFF",
            borderType: "solid",
            borderCap: "round",
          },
          emphasis: {
            label: {
              color: "transparent",
            },
            itemStyle: {
              areaColor: "#FFF",
            },
          },
        },
      ],
    }),
    [schema]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !baseOption) return;
    const mapName = baseOption.series[0].map;
    if (isNil(mapName) || isNil(echarts.getMap(mapName))) return;
    let chart;
    if (chartRef.current === null) {
      const worldChart = echarts.init(container, null, {
        renderer: "canvas",
        useDirtyRect: true,
      });
      chartRef.current = worldChart;
      chart = worldChart;
    } else {
      chart = chartRef.current;
    }

    chart.setOption(baseOption);
    chart.on("click", onRegionClicked);
  }, [baseOption, containerRef, onRegionClicked]);

  useEffect(() => {
    if (chartRef.current === null) return;
    const chart = chartRef.current;
    const copy: any = cloneDeep(baseOption);
    copy.series[0].data = data;
    chart.setOption(copy);
  }, [data, baseOption]);

  return <div className="w-full h-96 bg-neutral-900" ref={containerRef} />;
};
