import { FC, Fragment, useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import { ChartProps } from "./Render";
import {
  CHART_MIN_HEIGHT_BY_TYPE,
  CHART_MIN_WIDTH_BY_TYPE,
} from "../../../../features/vehicle/domain/usecase/ChartLayout";
import { LevelChartItemConfig } from "../../../domain/type/ChartConfig";
import "echarts-liquidfill";
import { cloneDeep } from "lodash";
import { EChartsOption, SeriesOption } from "echarts";

type CustomLiquidChartSerieOption = Omit<SeriesOption, "type"> & {
  waveAnimation: boolean;
  shape: string;
  outline: any;
  extra: any;
  radius: string | number;
  type: "liquidFill";
  backgroundStyle: any;
  label: any;
  name: string;
};

interface CustomEchartsOption extends Omit<EChartsOption, "series"> {
  series: CustomLiquidChartSerieOption[];
  opts: any;
}

export const LiquidLevelChart: FC<
  ChartProps<LevelChartItemConfig, LevelChartItemConfig>
> = ({ chart, data, width, height }) => {
  const [liquidLevels, setLiquidLevels] = useState<Array<CustomEchartsOption>>(
    []
  );
  const baseLevels = useMemo<Array<EChartsOption>>(() => {
    const cols = Math.floor(width / CHART_MIN_WIDTH_BY_TYPE.dashboard_level);
    const rows = Math.floor(height / CHART_MIN_HEIGHT_BY_TYPE.dashboard_level);
    const widthPerItem = width / Math.max(cols, 1);
    const heightPerItem = height / Math.max(rows, 1);
    const axisLabelTitleFontSize =
      18 * Math.max(1, Math.round(widthPerItem / width));
    const axisLabelContentFontSize =
      16 * Math.max(1, Math.round(widthPerItem / width));
    return (
      chart.ChartDefinition.schema.settings.usage?.map((it) => {
        const {
          dependency_key,
          animate,
          color,
          shape,
          title,
          outline,
          background_color,
          border_color,
          label_color,
          max,
          value_precision,
        } = it;
        const series: CustomLiquidChartSerieOption[] = [];

        series.push({
          type: "liquidFill" as any,
          radius: "90%",
          name: dependency_key,
          color: [color],
          waveAnimation: animate,
          shape: shape,
          outline: {
            borderDistance: 0,
            show: outline ?? false,
            itemStyle: {
              color: border_color ?? "#000",
              borderWidth: 3,
              borderColor: border_color ?? "#000",
            },
          },

          backgroundStyle: {
            color: background_color ?? "#FFF",
            borderWidth: 0,
          },

          label: {
            align: "center",

            formatter: function (param: any) {
              return `{title|Máximo} \n{content|${param.data
                ?.max}}\n{title|Posición} \n{content|${param.data?.realValue.toFixed(
                value_precision ?? 0
              )} (${(param.data?.value * 100).toFixed(
                value_precision ?? 0
              )}%)}`;
            },
            rich: {
              title: {
                color: label_color ?? "#000",
                fontSize: axisLabelTitleFontSize,
                fontWeight: "bold",
                lineHeight: 0,
                align: "center",
              },
              content: {
                color: label_color ?? "#000",
                fontSize: axisLabelContentFontSize,
                lineHeight: 0,
                align: "center",
              },
            },
          },
          data: [0],
          extra: {
            max: max,
          },
        });
        const result: EChartsOption = {
          series: series as any,
          opts: {
            width: widthPerItem,
            height: heightPerItem,
          },
          tooltip: {
            // trigger: "axis",
            // axisPointer: {
            //   type: "none",
            // },
            formatter: function (params) {
              const data = (params as any).data;
              return `
              <strong class='underline'>${title}</strong><br/>
              <dl>
                <dt><strong>Posición</strong></dt>
                <dd>${data.realValue.toFixed(value_precision ?? 0)} (${(
                  data.value * 100
                ).toFixed(value_precision ?? 0)}%)</dd>

                <dt><strong>Total</strong></dt>
                <dd>${data.max}</dd>
              </dl>`;
            },
            show: true,
          },
        };
        return result;
      }) ?? []
    );
  }, [chart, width, height]);

  useEffect(() => {
    if (!!data) {
      const copy = cloneDeep(baseLevels) as CustomEchartsOption[];
      copy.forEach((_, idx) => {
        const item = copy[idx] as CustomEchartsOption;
        const series = item.series;
        if (!series) return;
        const serie = series[0];
        const max_value = data?.chartExtra?.max || serie.extra.max;
        const realValue = data[serie.name];
        const new_value = realValue / max_value;
        serie.data = [
          {
            value: new_value,
            realValue,
            max: max_value,
          },
        ];
      });

      setLiquidLevels(copy);
    }
  }, [data, baseLevels]);

  return (
    <Fragment>
      {liquidLevels.map((it, idx) => (
        <ReactECharts
          key={`gc-${it.series[0].name}-${idx}`}
          option={it}
          lazyUpdate
          opts={it.opts}
        />
      ))}
    </Fragment>
  );
};
