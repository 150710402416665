import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppResponsiveScreen } from "../../../../../app/hook/Breakpoint";
import { DashboardMobileLayout } from "./DashboardMobileLayout";
import { DashboardLargeLayout } from "./DashboardLargeLayout";
import { useDashboardLayoutViewModel } from "../ViewModel";
import { useAppCustomer } from "../../../../../app/hook/Customer";
import { useAppUser } from "../../../../../app/hook/User";
import { useDashboardAllowedRoutes } from "../hook/Routes";
import { SocketIOService } from "../../../../../app/service/SocketIO";
import { Outlet } from "react-router-dom";
import { AppLoader } from "../../../../presentation/component/AppLoader";
import { DashboardLayoutUserModal } from "./User";
import { notification } from "antd";

export const DashboardLayoutComponent: FC = () => {
  const { isMobileScreen } = useAppResponsiveScreen();
  const [collapsed, setCollapsed] = useState(true);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const BaseLayout = useMemo(() => {
    return isMobileScreen ? DashboardMobileLayout : DashboardLargeLayout;
  }, [isMobileScreen]);

  const {
    fetchCustomer,
    fetchCustomerState,
    onFetchCustomerStateReceived,
    fetchAllowedRoutes,
    onFetchAllowedRoutesStateReceived,
    fetchCurrentUser,
    fetchCurrentUserState,
    onFetchCurrentUserStateReceived,
    fetchAllowedRoutesState,
    fetchCustomerStyle,
    fetchCustomerStyleState,
    onFetchCustomerStyleStateReceived,
  } = useDashboardLayoutViewModel();

  const { setCustomer, setCustomerStyle } = useAppCustomer();
  const { setCustomerUser, customerUser } = useAppUser();
  const { setAllowedRoutes } = useDashboardAllowedRoutes();
  useEffect(() => {
    fetchCurrentUser();
    fetchCustomer();
    fetchAllowedRoutes();
    fetchCustomerStyle();
  }, []);

  useEffect(() => {
    if (!!fetchCustomerState && !fetchCustomerState.loading) {
      if (fetchCustomerState.hasError) {
        console.log(fetchCustomerState.error);
        notification.error({
          message: "Error",
          description: fetchCustomerState.error?.message,
        });
      } else {
        setCustomer(fetchCustomerState.data!!);
      }
      onFetchCustomerStateReceived();
    }
  }, [fetchCustomerState]);

  useEffect(() => {
    if (!!fetchCustomerStyleState && !fetchCustomerStyleState.loading) {
      if (fetchCustomerStyleState.hasError) {
        console.log(fetchCustomerStyleState.error);
        notification.error({
          message: "Error",
          description: fetchCustomerStyleState.error?.message,
        });
      } else {
        setCustomerStyle(fetchCustomerStyleState.data!!);
      }
      onFetchCustomerStyleStateReceived();
    }
  }, [fetchCustomerStyleState]);

  useEffect(() => {
    if (!!customerUser?.id) {
      SocketIOService.socketConnect(customerUser.id);
    }
    return () => {
      SocketIOService.socketDisconnect();
    };
  }, [customerUser]);

  useEffect(() => {
    if (!!fetchAllowedRoutesState && !fetchAllowedRoutesState.loading) {
      if (fetchAllowedRoutesState.hasError) {
        console.log(fetchAllowedRoutesState.error);
        notification.error({
          message: "Error",
          description: fetchAllowedRoutesState.error?.message,
        });
      } else {
        setAllowedRoutes(fetchAllowedRoutesState.data!!);
      }
      onFetchAllowedRoutesStateReceived();
    }
  }, [fetchAllowedRoutesState]);

  useEffect(() => {
    if (!!fetchCurrentUserState && !fetchCurrentUserState.loading) {
      if (fetchCurrentUserState.hasError) {
        notification.error({
          message: "Error",
          description: fetchCurrentUserState.error?.message,
        });
      } else {
        setCustomerUser(fetchCurrentUserState.data!!);
      }
      onFetchCurrentUserStateReceived();
    }
  }, [fetchCurrentUserState]);

  const switchUserModal = useCallback(() => {
    setUserModalVisible((old) => !old);
  }, [setUserModalVisible]);

  const onActionClick = useCallback(
    (key: string) => {
      switch (key) {
        case "menu":
          setCollapsed((old) => !old);
          break;
        case "user":
          switchUserModal();
          break;
      }
    },
    [setCollapsed, switchUserModal]
  );

  return (
    <BaseLayout onActionClick={onActionClick} collapsed={collapsed}>
      {userModalVisible ? (
        <DashboardLayoutUserModal onClose={switchUserModal} />
      ) : null}

      <AppLoader
        loading={
          (!!fetchCustomerState && fetchCurrentUserState?.loading) ||
          (!!fetchCurrentUserState && fetchCurrentUserState.loading) ||
          (!!fetchAllowedRoutesState && fetchAllowedRoutesState.loading)
        }
      />
      <Outlet />
    </BaseLayout>
  );
};
