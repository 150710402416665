import { FC, PropsWithChildren, createContext, useState } from "react";
import { Customer } from "../../features/customer/domain/type/Customer";
import { CustomerStyle } from "../../features/customer/domain/type/CustomerStyle";

type Value = {
  customer: Customer | null;
  setCustomer: (customer: Customer) => void;
  customerStyle: CustomerStyle | null;
  setCustomerStyle: (customerStyle: CustomerStyle) => void;
};

export const AppCustomerContext = createContext<Value>({
  customer: null,
  setCustomer: null!,
  customerStyle: null,
  setCustomerStyle: null!,
});

export const AppCustomerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [customerStyle, setCustomerStyle] = useState<CustomerStyle | null>(
    null
  );
  return (
    <AppCustomerContext.Provider
      value={{
        customer,
        setCustomer,
        customerStyle,
        setCustomerStyle,
      }}
    >
      {children}
    </AppCustomerContext.Provider>
  );
};
