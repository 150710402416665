import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { WorldMapChart } from "../../../../../core/presentation/component/charts/WorldMap";
import * as echarts from "echarts/core";
import worldMapData from "../../../../../assets/json/world.json";
import peruMapData from "../../../../../assets/json/peru.json";
import { Gateway } from "../../../../gateway/domain/type/Gateway";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
type Props = {
  gatewayList: Gateway[];
};

export const OverviewWorldCounterMap: FC<Props> = ({ gatewayList }) => {
  const [showByRegion, setShowByRegion] = useState<string | null>(null);

  useEffect(() => {
    echarts.registerMap("world", worldMapData as any);
    echarts.registerMap("Peru", peruMapData as any);
  }, []);

  const worldWideCount = useMemo(() => {
    return [{ name: "Peru", value: gatewayList.length }];
  }, [gatewayList]);

  const localCount = useMemo(() => {
    return [{ name: "Lima", value: gatewayList.length }];
  }, [gatewayList]);

  const onRegionSelected = useCallback(
    (name: string) => {
      if (name === "Peru") {
        setShowByRegion(name);
      }
    },
    [setShowByRegion]
  );

  const onCloseByRegion = useCallback(() => {
    setShowByRegion(null);
  }, [setShowByRegion]);

  return (
    <div className="p-0 m-0 overflow-hidden rounded-md relative">
      {showByRegion === null ? (
        <WorldMapChart
          schema={{
            map: "world",
            max: Math.min(gatewayList.length, 100),
            min: 0,
            title: "Vehículos en línea",
            subtitle: "Vehículos en línea",
            unit: "vehículos",
          }}
          data={worldWideCount}
          onSelected={onRegionSelected}
        />
      ) : (
        <Fragment>
          <Button
            className="absolute left-0 top-0 m-2 z-50"
            icon={<FontAwesomeIcon icon={faClose} />}
            onClick={onCloseByRegion}
          />
          <WorldMapChart
            schema={{
              map: "Peru",
              max: Math.min(gatewayList.length, 100),
              min: 0,
              title: "Vehículos en línea",
              subtitle: "Vehículos en línea",
              unit: "vehículos",
            }}
            data={localCount}
          />
        </Fragment>
      )}
    </div>
  );
};
