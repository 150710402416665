import { useState } from "react";
import { AppTaskState } from "../../../../../app/domain/type/State";
import { LocationAddressRepository } from "../../../../data/repository/LocationAddress";
import { GatewayLocation } from "../../../../domain/type/DeviceData";
import { AppTaskStateG } from "../../../../../app/domain/model/State";
import { GatewayInfo } from "../../../../../features/gateway/domain/type/Gateway";
import { GatewayRepository } from "../../../../../features/gateway/data/repository/Gateway";

export function useUnitDescriptionControlViewModel() {
  const [fetchAddressState, setFetchAddressState] =
    useState<AppTaskState<boolean> | null>(null);
  const [address, setAddress] = useState<string | null | undefined>(null);
  const [fetchGatewayInfoState, setFetchGatewayInfoState] =
    useState<AppTaskState<GatewayInfo> | null>(null);

  async function fetchCoordinatesAddress(gatewayLocation: GatewayLocation) {
    if (fetchAddressState?.loading) return;
    try {
      const address =
        await LocationAddressRepository.getAddress(gatewayLocation);
      setAddress(address?.display_name);
      setFetchAddressState(AppTaskStateG.success(true));
    } catch (error: any) {
      setFetchAddressState(AppTaskStateG.error(error));
    }
  }

  function onFetchAddressStateReceived() {
    setFetchAddressState(null);
  }

  async function fetchGatewayInfo(gatewayId: number) {
    if (fetchGatewayInfoState?.loading) return;
    try {
      const response = await GatewayRepository.getGatewayInfo(gatewayId);
      setFetchGatewayInfoState(AppTaskStateG.success(response.data!!));
    } catch (error: any) {
      setFetchGatewayInfoState(AppTaskStateG.error(error));
    }
  }

  function onFetchGatewayInfoStateReceived() {
    setFetchGatewayInfoState(null);
  }

  return {
    fetchAddressState,
    address,
    fetchCoordinatesAddress,
    onFetchAddressStateReceived,
    fetchGatewayInfoState,
    fetchGatewayInfo,
    onFetchGatewayInfoStateReceived,
  };
}
