import { RemoteUnityAssetSource } from "../source/RemoteUnityAsset";

export class UnityAssetRepository {
  static async getUnityAsset(vehicleId: number, key: string) {
    return await RemoteUnityAssetSource.getUnityAsset(vehicleId, key);
  }
  static async getUnityGallery(vehicleId: number) {
    return await RemoteUnityAssetSource.getUnityGallery(vehicleId);
  }
}
