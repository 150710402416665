import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Map, Marker, divIcon } from "leaflet";
import stringToColor from "string-to-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { renderToString } from "react-dom/server";
import { ReactComponent } from "../../../assets/image/marker.svg";
import { Gateway } from "../../../features/gateway/domain/type/Gateway";
import { GatewayLocation } from "../../domain/type/DeviceData";
import { SocketIOService } from "../../../app/service/SocketIO";
import { UnitsMapFullScreenControl } from "./units_map/FullscreenControl";
import { UnitDescriptionControl } from "./units_map/UnitDescriptionControl";

export const MultipleGatewayMap: FC<{
  gatewayList?: Array<Gateway>;
  selectedGateway?: Gateway | null;
  onCloseSelectedGateway?: () => void;
}> = ({ gatewayList, selectedGateway, onCloseSelectedGateway }) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const map = useRef<Map>(null);
  const markers = useRef<{ [key: string]: Marker }>({});
  const [restoreSize, setRestoreSize] = useState<boolean>(false);
  const centerOnGateway = useCallback(
    (item: Gateway) => {
      const currentMarker = markers.current[item.key!];
      if (!!currentMarker) {
        map.current?.panTo(currentMarker.getLatLng());
      }
    },
    [markers, map]
  );
  const listeners = useMemo(() => {
    const currentGatewayList = gatewayList;
    return currentGatewayList?.map((it) => {
      return {
        event: `data/${it.key}/gps_live`,
        callback: (data: GatewayLocation) => {
          const currentMarker = markers.current[it.key];
          if (!!currentMarker) {
            currentMarker.setLatLng([data.latitude, data.longitude]);
          } else {
            const marker = new Marker([data.latitude, data.longitude], {
              icon: divIcon({
                iconSize: [32, 32],
                iconAnchor: [16, 32],
                html: renderToString(
                  <div className="w-full h-full relative">
                    <ReactComponent
                      style={{
                        fill: stringToColor(it!!.key),
                      }}
                      className="w-full h-full"
                    />
                    <div className="inset-0 mx-auto mt-1 absolute h-1/2 bg-transparent rounded-full aspect-square fill-white text-white">
                      <FontAwesomeIcon icon={faTruck} />
                    </div>
                  </div>
                ),
                className: "bg-transparent",
              }),
              pane: "markerPane",
              title: it.Vehicle?.name,
            });
            marker.addTo(map.current!);
            markers.current[it.key] = marker;
          }
          if (!!selectedGateway) {
            centerOnGateway(selectedGateway);
          }
        },
      };
    });
  }, [gatewayList, markers, map, selectedGateway, centerOnGateway]);

  useEffect(() => {
    const currentListeners = listeners;
    currentListeners?.forEach((it) => {
      SocketIOService.socketOn(it.event, it.callback);
    });
    return () => {
      currentListeners?.forEach((it) => {
        SocketIOService.socketOff(it.event, it.callback);
      });
    };
  }, [listeners]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setRestoreSize(true);
    return () => {
      setRestoreSize(false);

      (map as MutableRefObject<Map | null>).current = null;
    };
  }, []);

  useEffect(() => {
    if (!!map?.current) {
      const current = timeoutRef.current;
      if (!!current) {
        clearTimeout(current);
      }
      timeoutRef.current = setTimeout(() => {
        if (map?.current?.invalidateSize) {
          map?.current?.invalidateSize();
        }
      }, 200);
    }
  }, [restoreSize]);
  return (
    <div ref={containerRef} className="w-full h-full">
      <MapContainer
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
        zoom={7}
        scrollWheelZoom={false}
        ref={map}
        center={[-12.04318, -77.02824]}
      >
        <UnitsMapFullScreenControl containerRef={containerRef} />
        <UnitDescriptionControl
          gateway={selectedGateway}
          onCenter={centerOnGateway}
          onClose={onCloseSelectedGateway}
        />

        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
};
