import { ItemType } from "antd/lib/menu/hooks/useItems";
import { DashboardPathsType } from "../type/Paths";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faMagnifyingGlassChart,
  faGauge,
  faMapLocationDot,
  faIndustry,
  faHomeAlt,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

export class DashboardMenuUseCase {
  static getMenuItems(paths: DashboardPathsType): ItemType<MenuItemType>[] {
    const items: ItemType<MenuItemType>[] = [];

    if (!!paths.overview) {
      items.push({
        key: paths.overview,
        label: "Inicio",
        icon: <FontAwesomeIcon icon={faHomeAlt} />,
      });
    }
    if (!!paths.fleet_list) {
      items.push({
        key: paths.fleet_list,
        label: "Flota",
        icon: <FontAwesomeIcon icon={faIndustry} />,
      });
    }
    if (!!paths.fleet_tracking) {
      items.push({
        key: paths.fleet_tracking,
        label: "Tracking",
        icon: <FontAwesomeIcon icon={faMapLocationDot} />,
      });
    }
    if (!!paths.fleet_dashboard) {
      items.push({
        key: paths.fleet_dashboard,
        label: "Gráficos en tiempo real",
        icon: <FontAwesomeIcon icon={faGauge} />,
      });
    }
    // if (!!paths.fleet_historical) {
    //   items.push({
    //     key: paths.fleet_historical,
    //     label: "Gráficos Históricos",
    //     icon: <FontAwesomeIcon icon={faChartLine} />,
    //   });
    // }
    // if (!!paths.fleet_reports) {
    //   items.push({
    //     key: paths.fleet_reports,
    //     label: "Reportes",
    //     icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
    //   });
    // }
    if (!!paths.customer) {
      items.push({
        key: paths.customer,
        label: "Perfil de Cliente",
        icon: <FontAwesomeIcon icon={faAddressCard} />,
      });
    }
    return items;
  }
}
