import * as Nominatim from "nominatim-browser";
import { GatewayLocation } from "../../domain/type/DeviceData";

export class LocationAddressRepository {
  static async getAddress(location: GatewayLocation): Promise<
    | (Nominatim.NominatimResponse & {
        error?: string;
      })
    | undefined
  > {
    try {
      return await Nominatim.reverseGeocode({
        lat: location.latitude.toString(),
        lon: location.longitude.toString(),
        addressdetails: true,
      });
    } catch (e) {}
  }
}
