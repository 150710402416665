import { Col, Flex, Row, Typography } from "antd";
import { FC, useCallback, useState } from "react";
import { OverviewFleetList } from "./FleetList";
import { Gateway } from "../../../../gateway/domain/type/Gateway";
import { OverviewTrackerMap } from "./TrackerMap";
import { OverviewWorldCounterMap } from "./WorldCounterMap";
import { OverviewUnitInfo } from "./UnitDescription";

type Props = {};

const gatewayMockingData: Array<Gateway> = new Array(10)
  .fill(0)
  .map((_, index) => ({
    id: index,
    key: `gateway-${index}`,
    description: `Gateway ${index}`,
    updatedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
    allowScanning: true,
    info: {
      date: 0,
      eth_ip: " ",
      free_space: "0",
      memory_usage_percent: "0",
      num_gps_sat: 0,
      ping_acme: 0,
      temperature: "0",
      total_space: "0",
      used_space: "0",
      used_space_percent: "0",
      vbat: "0",
    },
    Vehicle: {
      id: index,
      name: `Vehicle ${index}`,
      plate: `Plate ${index}`,
      brand: `Brand ${index}`,
      model: `Model ${index}`,
      description: `Description ${index}`,
      photo: `https://picsum.photos/200/200?random=${index}`,
      enabled: true,
      Customer: {
        id: index,
        name: `Customer ${index}`,
        email: "",
        phone: "",
        businessName: "",
        ruc: "",
        subdomain: "",
        createdAt: "",
        updatedAt: "",
        address: "",
        postalCode: "",
        enabled: true,
        endAt: "",
      },
      createdAt: "",
      updatedAt: "",
      endAt: "",
    },
  }));

export const OverviewFleetSection: FC<Props> = ({}) => {
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(null);
  const onGatewaySelected = useCallback(
    (gateway: Gateway) => {
      setSelectedGateway(gateway);
    },
    [setSelectedGateway]
  );
  return (
    <Flex vertical>
      <Typography.Title level={3}>Flota</Typography.Title>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="h-96">
          <OverviewFleetList
            gatewayList={gatewayMockingData}
            onSelectedGateway={onGatewaySelected}
            selectedGateway={selectedGateway}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="h-96">
          <OverviewTrackerMap gatewayList={gatewayMockingData} />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} className="h-96">
          <OverviewWorldCounterMap gatewayList={gatewayMockingData} />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <OverviewUnitInfo />
        </Col>
      </Row>
    </Flex>
  );
};
