export const APP_CHART_TYPES = {
  dashboard_linear: "dashboard_linear",
  dashboard_area: "dashboard_area",
  dashboard_gauge: "dashboard_gauge",
  dashboard_level: "dashboard_level",
};

export type GaugeChartItemConfig = {
  dependency_key: string;
  title: string;
  unit: string;
  min_value: number;
  max_value: number;
  angle_start: number;
  angle_end: number;
  split: number;
  ranges: Array<{
    from: number;
    to: number;
    color: string;
  }>;
  split_line_thickness: number;
  split_line_color: string;
  value_precision: number;
  title_color: string;
  pointer_color: string;
  gradient: boolean;
};

export type LinearChartItemConfig = {
  title: string;
  dependency_key: string;
  description: string;
  color: string;
};

export type LevelChartItemConfig = {
  animate: boolean;
  title: string;
  dependency_key: string;
  max: number;
  color: string;
  shape: string;
  outline: boolean;
  label_color: string;
  border_color: string;
  background_color: string;
  value_precision: number;
};

export interface ChartData<T = {}> {
  id: number;
  timestamp: number;
  chartExtra: T;
  [key: string]: any;
}
