import { RemoteCustomerSource } from "../source/Remote";

export class CustomerRepository {
  static async getCustomer() {
    return RemoteCustomerSource.getCustomer();
  }

  static async getCustomerDomainByEmail(email: string) {
    return RemoteCustomerSource.getCustomerDomainByEmail(email);
  }

  static async getCustomerStyle() {
    return RemoteCustomerSource.getCustomerStyle();
  }
}
