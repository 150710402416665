import { DashboardPaths } from "../implementation/Paths";
import { DashboardAllowedRoutes } from "../type/AllowedRoutes";
import { DashboardPathsType } from "../type/Paths";

export class DashboardPathsUseCase {
  static getPathsFromAllowedRoutes(
    allowedRoutes?: DashboardAllowedRoutes
  ): DashboardPathsType {
    const allowedPaths: DashboardPathsType = {};
    if (!!allowedRoutes) {
      if (allowedRoutes.fleet_list)
        allowedPaths.fleet_list = DashboardPaths.fleet_list;
      if (allowedRoutes.fleet_list)
        allowedPaths.fleet_tracking = DashboardPaths.fleet_tracking;
      if (allowedRoutes.fleet_list)
        allowedPaths.fleet_dashboard = DashboardPaths.fleet_dashboard;
      if (allowedRoutes.fleet_list)
        allowedPaths.fleet_reports = DashboardPaths.fleet_reports;
      if (allowedRoutes.fleet_list)
        allowedPaths.fleet_historical = DashboardPaths.fleet_historical;
      if (allowedRoutes.unit_info)
        allowedPaths.unit_p_id = DashboardPaths.unit_p_id;
      if (allowedRoutes.customer_info)
        allowedPaths.customer = DashboardPaths.customer;
      if (allowedRoutes.gateway_list)
        allowedPaths.gateway_list = DashboardPaths.gateway_list;
      if (allowedRoutes.gateway_info)
        allowedPaths.gateway_p_id = DashboardPaths.gateway_p_id;
      if (allowedRoutes.user_list)
        allowedPaths.user_list = DashboardPaths.user_list;
      if (allowedRoutes.user_info)
        allowedPaths.user_p_id = DashboardPaths.user_p_id;
      if (allowedRoutes.overview)
        allowedPaths.overview = DashboardPaths.overview;
    }
    return allowedPaths;
  }
}
