import { FC, useMemo } from "react";
import { GaugeChart } from "./Gauge";
import { APP_CHART_TYPES, ChartData } from "../../../domain/type/ChartConfig";
import { AppEmptyContentStateComponent } from "../State";
import { VehicleChart } from "../../../../features/gateway/domain/type/VehicleChart";
import { useComponentSize } from "../../../../app/hook/Size";
import { LiquidLevelChart } from "./LiquidLevel";
import { LinearChart } from "./Linear";
type Props = {
  chart: VehicleChart;
  data: ChartData | null;
};

export type ChartProps<T = {}, E = {}> = {
  chart: VehicleChart<T>;
  data: ChartData<E> | null;
  width: number;
  height: number;
};

export function DeviceDataRender({ chart, data }: Props) {
  const { ref, width, height } = useComponentSize();
  const Element = useMemo(() => {
    const chartType = chart.ChartDefinition.ChartSchema.type;
    switch (chartType) {
      case APP_CHART_TYPES.dashboard_gauge:
        return GaugeChart;
      case APP_CHART_TYPES.dashboard_level:
        return LiquidLevelChart;
      case APP_CHART_TYPES.dashboard_linear:
        return LinearChart;
      default:
        return InvalidGraphic;
    }
  }, [chart]);

  return (
    <div
      className="w-full h-full overflow-hidden flex flex-row flex-wrap"
      ref={ref}
    >
      <Element
        chart={chart as any}
        data={data as any}
        width={width}
        height={height}
      />
    </div>
  );
}

const InvalidGraphic: FC<ChartProps> = ({ chart }) => {
  return (
    <div className={"flex flex-col items-center justify-center-4"}>
      <AppEmptyContentStateComponent
        description={`La configuración para ${chart.ChartDefinition.name} no es válida.`}
        title={"Gráfico inválido"}
      />
    </div>
  );
};
