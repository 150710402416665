import AcmeIcon from "../../../assets/image/AICON.png";
import { useState, useEffect, DOMAttributes } from "react";
import { useAppCustomer } from "../../../app/hook/Customer";
import { hostConfig } from "../../../app/service/RemoteClient";

export const CustomerLogo: React.FC = () => {
  const { customerStyle } = useAppCustomer();
  const [error, setError] = useState(false);

  const handleImageError: DOMAttributes<HTMLImageElement>["onError"] = (e) => {
    setError(true);
  };

  useEffect(() => {
    if (!!customerStyle?.logoFileName) {
      setError(false);
    } else {
      setError(true);
    }
  }, [customerStyle]);

  return error ? (
    <img
      src={AcmeIcon}
      alt={"ACME"}
      className="object-contain h-full w-auto aspect-square max-h-32"
    />
  ) : (
    <img
      className="w-full h-full"
      src={`${hostConfig.host}${customerStyle?.logoFileName}`}
      alt={"Cliente"}
      onError={handleImageError}
    />
  );
};
