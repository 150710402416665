import {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import { ChartData } from "../../../../../../../core/domain/type/ChartConfig";
import { SocketIOService } from "../../../../../../../app/service/SocketIO";
import { Avatar, Typography } from "antd";
import { LiveDot } from "../../../../../../../core/presentation/component/LiveDot";
import { DeviceDataRender } from "../../../../../../../core/presentation/component/charts/Render";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { VehicleChart } from "../../../../../../gateway/domain/type/VehicleChart";
import { DateUtil } from "../../../../../../../core/util/Date";

type Props = {
  chart: VehicleChart;
};
export const DashboardChartRenderer: FC<Props> = ({ chart }) => {
  const [data, setData] = useState<ChartData | null>(null);
  const [date, setDate] = useState<string | undefined>();
  const timerRef = useRef<NodeJS.Timeout>(
    null
  ) as MutableRefObject<NodeJS.Timeout>;
  const [isLive, setIsLive] = useState<boolean>(false);

  const timerCallback = useCallback(() => {
    setIsLive(false);
  }, [setIsLive]);

  const listener = useCallback(
    (nData: ChartData) => {
      if (timerRef.current) clearTimeout(timerRef?.current);
      if (!!nData) {
        setData(nData);
        if (!!nData.timestamp) {
          setDate(DateUtil.fastFormatDate(nData.timestamp, "HH:mm:ss"));
          setIsLive(true);
        }
      }
      timerRef.current = setTimeout(timerCallback, 10000);
    },
    [setData, setDate, timerRef, setIsLive, timerCallback]
  );
  useEffect(() => {
    const key = `data/${chart.ChartDefinition.key}`;
    SocketIOService.socketOn(key, listener);
    return () => {
      SocketIOService.socketOff(key, listener);
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [chart, listener]);

  return (
    <div
      className={
        "w-full h-full rounded-xl bg-white border border-solid border-neutral-200 "
      }
    >
      <div className={"flex flex-col w-full h-full overflow-hidden"}>
        <div className="flex flex-row gap-2 items-center px-5 pt-3 flex-wrap">
          {isLive ? (
            <>
              <LiveDot />
              <span className="font-semibold font-quicksand text-xs">
                {date}
              </span>
            </>
          ) : (
            <>
              <Avatar size={"small"} className={"bg-red-500 text-white "}>
                <FontAwesomeIcon icon={faSignal} />
              </Avatar>
              <span className="font-semibold font-quicksand text-xs">
                Desconectado
              </span>
            </>
          )}
        </div>
        {chart.ChartDefinition.name && (
          <Typography.Text className="font-quicksand font-semibold text-sm text-center">
            {chart.ChartDefinition.name}
          </Typography.Text>
        )}

        <div className="w-full h-full px-2 py-2 overflow-hidden">
          <DeviceDataRender chart={chart} data={data} />
        </div>
      </div>
    </div>
  );
};
