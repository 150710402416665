import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ThemeConfig } from "antd";
import "dayjs/locale/es";
import reactUseCookie from "react-use-cookie";
import { AppThemeUseCase } from "../domain/usecase/Theme";
import {
  AppColorPalette,
  AppRegularColorPalette,
  kDEFAULT_REGULAR_COLOR_PALETTE,
} from "../domain/type/AppPalette";
import { useAppCustomer } from "../hook/Customer";

type Themes = {
  primaryTheme: ThemeConfig;
  menuTheme: ThemeConfig;
  secondaryMenuTheme: ThemeConfig;
};

type Value = {
  isDarkThemeEnabled: boolean;
  switchDarkTheme: () => void;
  colorPalette: AppColorPalette;
  updateColorPalette: (palette: AppRegularColorPalette) => void;
  themes: Themes;
};

export const AppThemeContext = createContext<Value>({
  isDarkThemeEnabled: false,
  switchDarkTheme: null!,
  colorPalette: null!,
  updateColorPalette: null!,
  themes: null!,
});

export const AppThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [palette, setPalette] = useState<AppColorPalette>(
    AppThemeUseCase.createPalette(kDEFAULT_REGULAR_COLOR_PALETTE)
  );
  const [darkMode, setDarkMode] = reactUseCookie(
    "darkMode",
    `${AppThemeUseCase.byDefaultUseDarkTheme()}`
  );
  const { customerStyle } = useAppCustomer();
  const isDark = useMemo(() => darkMode === "true", [darkMode]);
  const [themes, setThemes] = useState<Themes>({
    menuTheme: AppThemeUseCase.getMenuThemeConfig(palette),
    primaryTheme: AppThemeUseCase.getPrimaryThemeConfig(isDark, palette),
    secondaryMenuTheme: AppThemeUseCase.getSecondaryMenuThemeConfig(palette),
  });

  const updateColorPalette = useCallback(
    (base: AppRegularColorPalette) => {
      setPalette(AppThemeUseCase.createPalette(base));
    },
    [setPalette]
  );

  const switchDarkTheme = useCallback(() => {
    setDarkMode(darkMode === "true" ? "false" : "true");
  }, [setDarkMode, darkMode]);

  useEffect(() => {
    setThemes({
      menuTheme: AppThemeUseCase.getMenuThemeConfig(palette),
      primaryTheme: AppThemeUseCase.getPrimaryThemeConfig(isDark, palette),
      secondaryMenuTheme: AppThemeUseCase.getSecondaryMenuThemeConfig(palette),
    });
  }, [isDark, palette]);

  useEffect(() => {
    if (!!customerStyle) {
      updateColorPalette({
        colorPrimary: customerStyle.primaryColor,
        colorSecondary: customerStyle.secondaryColor,
        colorTertiary: customerStyle.tertiaryColor,
        colorQuaternary: customerStyle.quaternaryColor,
      });
    } else {
      updateColorPalette(kDEFAULT_REGULAR_COLOR_PALETTE);
    }
  }, [customerStyle, updateColorPalette]);

  return (
    <AppThemeContext.Provider
      value={{
        colorPalette: palette,
        isDarkThemeEnabled: isDark,
        switchDarkTheme,
        themes: themes,
        updateColorPalette,
      }}
    >
      <div
        style={
          {
            "--primary-color": palette.colorPrimary,
            "--text-primary-color": palette.textColorOnPrimary,
            "--secondary-color": palette.colorSecondary,
            "--text-secondary-color": palette.textColorOnSecondary,
            "--sider-color": palette.colorTertiary,
            "--text-sider-color": palette.textColorOnTertiary,
            "--sider_header-color": palette.colorQuaternary,
            "--text-sider_header_color": palette.textColorOnQuaternary,
            "--background-color": palette.backgroundColor,
          } as any
        }
        className={`w-screen h-screen overflow-hidden ${isDark ? "dark" : ""}`}
      >
        {children}
      </div>
    </AppThemeContext.Provider>
  );
};
