import { FC, useMemo } from "react";
import { VehicleChart } from "../../../gateway/domain/type/VehicleChart";
import { Card } from "antd";
import { useComponentSize } from "../../../../app/hook/Size";
import { ChartLayoutUseCase } from "../../../vehicle/domain/usecase/ChartLayout";
import ReactGridLayout from "react-grid-layout";
import { toNumber } from "lodash";
import { DashboardChartRenderer } from "../../../vehicle/presentation/component/tabs/device/components/ChartRenderer";

type Props = {
  group: VehicleChart[];
};

export const DashboardPageVehicleChartGroup: FC<Props> = ({ group }) => {
  const { ref, width, height } = useComponentSize();
  const layout = useMemo(() => {
    if (!!group && !!width) {
      return ChartLayoutUseCase.generateLayout(width, group);
    }
    return { layout: [], rows: 0, cols: 0, rowHeight: 0 };
  }, [group, width]);
  return (
    <Card ref={ref} title={group[0].Vehicle.name}>
      {!!layout && (
        <ReactGridLayout
          layout={layout.layout}
          cols={layout.cols}
          width={width}
          rowHeight={layout.rowHeight}
          className="layout"
        >
          {layout.layout.map((it, i) => {
            const currentChart = group!![toNumber(it.i.split("_")[1])];
            return (
              <div key={it.i} className="w-full h-full overflow-hidden">
                <DashboardChartRenderer chart={currentChart} />
              </div>
            );
          })}
        </ReactGridLayout>
      )}
    </Card>
  );
};
