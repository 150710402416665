import { isNil } from "lodash";
import {
  useState,
  DetailedHTMLProps,
  ImgHTMLAttributes,
  FC,
  ReactElement,
  useMemo,
} from "react";
import Zoom from "react-medium-image-zoom";

type Props = {
  errorComponent: ReactElement;
  imgProps: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  allowZooming?: boolean;
};

export const ImageHOC: FC<Props> = ({
  errorComponent,
  imgProps,
  allowZooming,
}) => {
  const [error, setError] = useState(isNil(imgProps.src));
  const handleImageError = () => {
    setError(true);
  };

  const Wrapper = useMemo(() => (allowZooming ? Zoom : "div"), [allowZooming]);

  return (
    <>
      {error ? (
        errorComponent
      ) : (
        <Wrapper>
          <img
            {...imgProps}
            onError={handleImageError}
            onErrorCapture={handleImageError}
            alt=""
          />
        </Wrapper>
      )}
    </>
  );
};
