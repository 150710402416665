import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  notification,
  Tree,
  Typography,
} from "antd";
import { Vehicle } from "../../../../domain/type/Vehicle";
import { TabContentBox } from "../../../../../../core/presentation/component/TabContentBox";
import { AppLoader } from "../../../../../../core/presentation/component/AppLoader";
import { AppStateComponent } from "../../../../../../core/presentation/component/State";
import { FullscreenToggle } from "../../../../../../core/presentation/component/Fullscreen";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faSearch,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import ReactGridLayout from "react-grid-layout";
import { ChartLayoutUseCase } from "../../../../domain/usecase/ChartLayout";
import { useComponentSize } from "../../../../../../app/hook/Size";
import animationData from "../../../../../../assets/animation/animation_d1.json";
import { useTabVehicleDashboardViewModel } from "./ViewModel";
import { TabVehicleModalScan } from "./components/ModalScan";
import { Gateway } from "../../../../../gateway/domain/type/Gateway";
import { difference, isEmpty, orderBy, toNumber } from "lodash";
import { DashboardChartRenderer } from "./components/ChartRenderer";
type Props = {
  vehicle: Vehicle;
};
export const VehicleDeviceTab: FC<Props> = ({ vehicle }) => {
  const [scan, setScan] = useState<boolean>(false);
  const [reorder, setReorder] = useState<boolean>(false);
  const { ref: c1Ref, width: c1Width } = useComponentSize();
  const [selectedChartIds, setSelectedChartIds] = useState<
    Array<number> | undefined
  >();
  const {
    dashboardChartList,
    dashboardScannedChartList,
    fetchDashboardChartList,
    fetchDashboardChartListState,
    fetchGatewayList,
    fetchGatewayListState,
    fetchUnitGalleryState,
    gatewayList,
    onFetchDashboardChartListStateReceived,
    onFetchGatewayListStateReceived,
    onScanDashboardChartListStateReceived,
    scanDashboardChartList,
    scanDashboardChartListState,
    onFetchUnitGalleryStateReceived,
  } = useTabVehicleDashboardViewModel();

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchDashboardChartList(vehicle.id);
    fetchGatewayList(vehicle.id);
  }, []);

  useEffect(() => {
    if (
      !!fetchDashboardChartListState &&
      !fetchDashboardChartListState.loading
    ) {
      if (fetchDashboardChartListState.hasError) {
        notification.error({
          message: "Error al obtener los dispositivos.",
          description: fetchDashboardChartListState.error?.message,
        });
      }
      onFetchDashboardChartListStateReceived();
    }
  }, [fetchDashboardChartListState]);

  useEffect(() => {
    if (!!fetchUnitGalleryState && !fetchUnitGalleryState.loading) {
      if (fetchUnitGalleryState.hasError) {
        notification.error({
          message: "Error al obtener la imagen del dispositivo.",
          description: fetchUnitGalleryState.error?.message,
        });
      }
      onFetchUnitGalleryStateReceived();
    }
  }, [fetchUnitGalleryState]);

  useEffect(() => {
    if (!!scanDashboardChartListState && !scanDashboardChartListState.loading) {
      if (scanDashboardChartListState.hasError) {
        notification.error({
          description: scanDashboardChartListState.error?.message,
          message: "Escanear Variables",
        });
      }
      onSwitchScan();
      onScanDashboardChartListStateReceived();
    }
  }, [scanDashboardChartListState]);

  useEffect(() => {
    if (!!fetchGatewayListState && !fetchGatewayListState.loading) {
      if (fetchGatewayListState.hasError) {
        notification.error({
          description: fetchGatewayListState.error?.message,
          message: "Obtener Gateways",
        });
      }
      onFetchGatewayListStateReceived();
    }
  }, [fetchGatewayListState]);

  const onChartsSelected = useCallback(
    (value: CheckboxValueType[]) => {
      setSelectedChartIds(value as any);
    },
    [setSelectedChartIds]
  );

  const selectedCharts = useMemo(
    () =>
      orderBy(
        dashboardChartList?.filter((it) => selectedChartIds?.includes(it.id)),
        (it) => selectedChartIds?.indexOf(it.id)
      ),
    [selectedChartIds, dashboardChartList]
  );

  const layout = useMemo(() => {
    if (!!selectedCharts && !!c1Width) {
      return ChartLayoutUseCase.generateLayout(c1Width, selectedCharts);
    }
    return { layout: [], rows: 0, cols: 0, rowHeight: 0 };
  }, [selectedCharts, c1Width]);

  const onSwitchReorder = useCallback(() => {
    setReorder((old) => !old);
  }, [setReorder]);

  const onSwitchScan = useCallback(() => {
    setScan((old) => !old);
  }, [setScan]);

  const onScanConfirm = useCallback(
    (gateway: Gateway) => {
      scanDashboardChartList(vehicle.id, gateway.id);
    },
    [scanDashboardChartList, vehicle]
  );

  const allChartsSelected = useMemo(
    () => difference(dashboardChartList, selectedCharts).length === 0,
    [dashboardChartList, selectedCharts]
  );

  const allScannedChartsSelected = useMemo(() => {
    const diff = difference(selectedCharts, dashboardScannedChartList);
    return diff.length === dashboardScannedChartList.length;
  }, [dashboardScannedChartList, selectedCharts]);

  const selectAllCharts = useCallback(() => {
    if (allChartsSelected) {
      setSelectedChartIds([]);
    } else {
      setSelectedChartIds(dashboardChartList.map((it) => it.id));
    }
  }, [setSelectedChartIds, allChartsSelected, dashboardChartList]);

  const selectAllScannedCharts = useCallback(() => {
    if (allScannedChartsSelected) {
      setSelectedChartIds([]);
    } else {
      setSelectedChartIds(dashboardScannedChartList.map((it) => it.id));
    }
  }, [
    setSelectedChartIds,
    allScannedChartsSelected,
    dashboardScannedChartList,
  ]);

  const onReorderDrop = useCallback(
    (info: any) => {
      const movedChartId = info.dragNode.key;
      const initialPosition = selectedChartIds!!.indexOf(movedChartId);
      const finalPosition = Math.max(0, info.dropPosition);

      const tempSelectedChartIdsCopy = [...selectedChartIds!!];
      const tempFutureId = tempSelectedChartIdsCopy[finalPosition];

      tempSelectedChartIdsCopy[initialPosition] = tempFutureId;
      tempSelectedChartIdsCopy[finalPosition] = movedChartId;
      setSelectedChartIds(tempSelectedChartIdsCopy);
    },
    [selectedChartIds]
  );

  return (
    <TabContentBox>
      <TabVehicleModalScan
        gatewayList={gatewayList}
        onCancel={onSwitchScan}
        onConfirm={onScanConfirm}
        open={scan}
      />
      <AppLoader
        loading={
          (!!fetchDashboardChartListState &&
            fetchDashboardChartListState.loading) ||
          (!!scanDashboardChartListState &&
            scanDashboardChartListState.loading) ||
          (!!fetchGatewayListState && fetchGatewayListState.loading)
        }
      />
      {!dashboardChartList || isEmpty(dashboardChartList) ? (
        <AppStateComponent
          description={"No hay dispositivos disponibles en esta unidad."}
          title={"No hay dispositivos"}
          animation={animationData}
        />
      ) : (
        <div
          className={
            "flex flex-col gap-2 overflow-x-hidden overflow-y-scroll pt-2"
          }
          ref={divRef}
        >
          <Typography.Text type={"secondary"}>
            Seleccionar variables para monitorear
          </Typography.Text>
          <Card>
            <Form layout={"vertical"}>
              <Form.Item
                label={
                  <Checkbox
                    onChange={selectAllCharts}
                    checked={allChartsSelected}
                  >
                    <Typography.Text strong>
                      Todas las variables
                    </Typography.Text>
                  </Checkbox>
                }
              >
                <Checkbox.Group
                  value={selectedChartIds}
                  onChange={onChartsSelected}
                >
                  {dashboardChartList?.map((it) => {
                    return (
                      <Checkbox key={`cb-${it.id}`} value={it.id}>
                        {it.ChartDefinition.name}
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </Form.Item>
              {!isEmpty(dashboardScannedChartList) && (
                <Fragment>
                  <Divider />
                  <Form.Item
                    label={
                      <Checkbox
                        onChange={selectAllScannedCharts}
                        checked={allScannedChartsSelected}
                      >
                        <Typography.Text strong>
                          Variables escaneadas
                        </Typography.Text>
                      </Checkbox>
                    }
                  >
                    <Checkbox.Group
                      value={selectedChartIds}
                      onChange={onChartsSelected}
                    >
                      {dashboardScannedChartList?.map((it) => {
                        return (
                          <Checkbox key={`cb-scan-${it.id}`} value={it.id}>
                            {it.ChartDefinition.name}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Form.Item>
                </Fragment>
              )}
              <Form.Item>
                <Button.Group>
                  <Button
                    type="primary"
                    icon={<FontAwesomeIcon icon={faSearch} />}
                    shape="round"
                    onClick={onSwitchScan}
                  >
                    Escanear Variables
                  </Button>
                  {!isEmpty(selectedCharts) && !reorder && (
                    <Button
                      type="primary"
                      icon={<FontAwesomeIcon icon={faUpDownLeftRight} />}
                      shape="round"
                      onClick={onSwitchReorder}
                    >
                      Reordenar
                    </Button>
                  )}
                </Button.Group>
              </Form.Item>
            </Form>
          </Card>
          {reorder && !isEmpty(selectedCharts) && (
            <Card
              title={
                <div className="flex flex-row gap-1">
                  <Button
                    type="primary"
                    size="small"
                    shape="circle"
                    icon={<FontAwesomeIcon icon={faClose} />}
                    onClick={onSwitchReorder}
                  />
                  Disposición de los gráficos
                </div>
              }
              size="small"
            >
              <Tree
                className="draggable-tree"
                draggable
                blockNode
                onDrop={onReorderDrop}
                treeData={selectedCharts!!.map((it) => ({
                  title: it.ChartDefinition.name,
                  key: it.id,
                }))}
              />
            </Card>
          )}

          {selectedChartIds && selectedChartIds?.length > 0 ? (
            <div
              className={
                "overflow-x-visible w-full flex-wrap flex flex-row justify-stretch gap-2"
              }
              ref={c1Ref}
            >
              <div>
                <FullscreenToggle
                  containerRef={divRef}
                  className={"absolute right-2 top-0"}
                />

                {!!layout && (
                  <ReactGridLayout
                    isDraggable={false}
                    layout={layout.layout}
                    cols={layout.cols}
                    width={c1Width}
                    rowHeight={layout.rowHeight}
                    className="layout"
                  >
                    {layout.layout.map((it, i) => {
                      const currentChart =
                        selectedCharts!![toNumber(it.i.split("_")[1])];
                      return (
                        <div
                          key={it.i}
                          className="w-full h-full overflow-hidden"
                        >
                          <DashboardChartRenderer chart={currentChart} />
                        </div>
                      );
                    })}
                  </ReactGridLayout>
                )}
              </div>
            </div>
          ) : (
            <AppStateComponent
              description={"Selecciona variables para visualizar."}
              title={"Selecciona las variables"}
              animation={animationData}
            />
          )}
        </div>
      )}
    </TabContentBox>
  );
};
