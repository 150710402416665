import { useState } from "react";
import { AppTaskState } from "../../../app/domain/type/State";
import { Vehicle } from "../../vehicle/domain/type/Vehicle";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { Gateway } from "../../gateway/domain/type/Gateway";
import { GatewayRepository } from "../../gateway/data/repository/Gateway";
import { isNil } from "lodash";
import { VehicleChart } from "../../gateway/domain/type/VehicleChart";
import { VehicleChartRepository } from "../../../core/data/repository/VehicleChart";

type VehicleChartGroup = Array<{
  vehicle: Vehicle;
  charts: VehicleChart[];
}>;

export function useFleetDashboardPageViewModel() {
  const [fetchChartListState, setFetchChartListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [charts, setCharts] = useState<VehicleChart[]>([]);
  const [chartGroups, setChartGroups] = useState<VehicleChartGroup>([]);

  async function fetchChartList() {
    if (fetchChartListState?.loading) {
      return;
    }
    try {
      const chartList = await VehicleChartRepository.getCustomerCharts({
        dashboard: true,
      });
      if (chartList.ok) {
        const group: VehicleChartGroup = [];
        chartList.data!!.forEach((gateway) => {
          if (!isNil(gateway.vehicleId)) {
            const index = group.findIndex(
              (g) => g.vehicle.id === gateway.vehicleId
            );
            if (index === -1) {
              group.push({
                vehicle: gateway.Vehicle!!,
                charts: [gateway],
              });
            } else {
              group[index].charts.push(gateway);
            }
          }
        });
        setCharts(chartList.data!!);
        setChartGroups(group);
        setFetchChartListState(AppTaskStateG.success(true));
      } else {
        setFetchChartListState(
          AppTaskStateG.error(new Error(chartList.message))
        );
      }
    } catch (error: any) {
      setFetchChartListState(AppTaskStateG.error(error));
    }
  }

  const onFetchChartListStateReceived = () => {
    setFetchChartListState(null);
  };

  return {
    fetchChartList,
    fetchChartListState,
    onFetchChartListStateReceived,
    charts,
    chartGroups,
  };
}
