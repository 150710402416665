import { FC, useEffect } from "react";
import { useOverviewViewModel } from "../ViewModel";
import {
  Avatar,
  Button,
  Card,
  List,
  Skeleton,
  Typography,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faRefresh, faUser } from "@fortawesome/free-solid-svg-icons";

export const ConnectedUsers: FC = () => {
  const {
    fetchConnectedUsers,
    fetchConnectedUsersState,
    onFetchConnectedUsersStateReceived,
    connectedUsers,
  } = useOverviewViewModel();

  useEffect(() => {
    void fetchConnectedUsers();
  }, []);

  useEffect(() => {
    if (!!fetchConnectedUsersState && !fetchConnectedUsersState.loading) {
      if (fetchConnectedUsersState.hasError) {
        notification.error({
          message: "Error",
          description: fetchConnectedUsersState.error?.message,
        });
      }
      onFetchConnectedUsersStateReceived();
    }
  }, [fetchConnectedUsersState, onFetchConnectedUsersStateReceived]);

  return (
    <Skeleton loading={fetchConnectedUsersState?.loading === true}>
      <div className="flex flex-col gap-2">
        <Typography.Text
          style={{ padding: 0, margin: 0 }}
          type="secondary"
          className="leading-tight"
        >
          {connectedUsers.length + " "} en línea
        </Typography.Text>
        <Card size="small">
          <List
            size="small"
            dataSource={connectedUsers}
            renderItem={(item) => {
              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<FontAwesomeIcon icon={faAt} />} />}
                    title={item.username}
                    description={item.corporateEmail}
                  />
                </List.Item>
              );
            }}
          />
        </Card>
      </div>
    </Skeleton>
  );
};
