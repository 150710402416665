import { useState } from "react";
import { VehicleChart } from "../../../../../gateway/domain/type/VehicleChart";
import { AppTaskState } from "../../../../../../app/domain/type/State";
import { AppTaskStateG } from "../../../../../../app/domain/model/State";
import { VehicleChartRepository } from "../../../../../../core/data/repository/VehicleChart";
import { UnitAsset } from "../../../../../../core/domain/type/UnitAsset";
import { Gateway } from "../../../../../gateway/domain/type/Gateway";
import { GatewayRepository } from "../../../../../gateway/data/repository/Gateway";
import { UnityAssetRepository } from "../../../../../../core/data/repository/UnityAsset";

export function useTabVehicleDashboardViewModel() {
  const [gatewayList, setGatewayList] = useState<Gateway[]>([]);
  const [dashboardChartList, setDashboardChartList] = useState<VehicleChart[]>(
    []
  );
  const [dashboardScannedChartList, setDashboardScannedChartList] = useState<
    VehicleChart[]
  >([]);
  const [fetchDashboardChartListState, setFetchDashboardChartListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fetchGatewayListState, setFetchGatewayListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [scanDashboardChartListState, setScanDashboardChartListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [fetchUnitGalleryState, setFetchUnitGalleryState] =
    useState<AppTaskState<boolean> | null>(null);
  const [unitGallery, setUnitGallery] = useState<UnitAsset[]>([]);

  async function scanDashboardChartList(vehicleId: number, gatewayId: number) {
    if (scanDashboardChartListState?.loading) return;
    setScanDashboardChartListState(AppTaskStateG.loading());
    try {
      const response = await VehicleChartRepository.scanVehicleCharts(
        gatewayId,
        vehicleId
      );
      if (response.ok) {
        setDashboardScannedChartList(response.data!!);
        setScanDashboardChartListState(AppTaskStateG.success(true));
      } else {
        setScanDashboardChartListState(
          AppTaskStateG.error(new Error(response.message!!))
        );
      }
      return true;
    } catch (e: any) {
      setScanDashboardChartListState(AppTaskStateG.error(e));
    }
  }

  function onScanDashboardChartListStateReceived() {
    setScanDashboardChartListState(null);
  }

  async function fetchDashboardChartList(vehicleId: number) {
    if (fetchDashboardChartListState?.loading) return;
    setFetchDashboardChartListState(AppTaskStateG.loading());
    try {
      const data = await VehicleChartRepository.getVehicleCharts(vehicleId, {
        dashboard: true,
      });
      if (data.ok) {
        setDashboardChartList(data.data!!);
        setFetchDashboardChartListState(AppTaskStateG.success(true));
      } else {
        setFetchDashboardChartListState(
          AppTaskStateG.error(new Error(data.message!!))
        );
      }
    } catch (error: any) {
      setFetchDashboardChartListState(AppTaskStateG.error(error));
    }
  }

  function onFetchDashboardChartListStateReceived() {
    setFetchDashboardChartListState(null);
  }

  async function fetchGatewayList(vehicleId: number) {
    if (fetchGatewayListState?.loading) return;
    setFetchGatewayListState(AppTaskStateG.loading());
    try {
      const data = await GatewayRepository.getVehicleGatewayList(vehicleId);
      if (data.ok) {
        setGatewayList(data.data!!);
        setFetchGatewayListState(AppTaskStateG.success(true));
      } else {
        setFetchGatewayListState(
          AppTaskStateG.error(new Error(data.message!!))
        );
      }
    } catch (error: any) {
      setFetchGatewayListState(AppTaskStateG.error(error));
    }
  }

  function onFetchGatewayListStateReceived() {
    setFetchGatewayListState(null);
  }

  async function fetchVehicleGallery(vehicleId: number) {
    if (fetchUnitGalleryState?.loading) return;
    setFetchUnitGalleryState(AppTaskStateG.loading());
    try {
      const data = await UnityAssetRepository.getUnityGallery(vehicleId);
      if (data.ok) {
        setUnitGallery(data.data!!);
        setFetchUnitGalleryState(AppTaskStateG.success(true));
      } else {
        setFetchUnitGalleryState(
          AppTaskStateG.error(new Error(data.message!!))
        );
      }
    } catch (error: any) {
      setFetchUnitGalleryState(AppTaskStateG.error(error));
    }
  }

  function onFetchUnitGalleryStateReceived() {
    setFetchUnitGalleryState(null);
  }

  return {
    gatewayList,
    fetchGatewayListState,
    fetchGatewayList,
    onFetchGatewayListStateReceived,
    dashboardChartList,
    fetchDashboardChartList,
    fetchDashboardChartListState,
    onFetchDashboardChartListStateReceived,
    scanDashboardChartList,
    scanDashboardChartListState,
    onScanDashboardChartListStateReceived,
    dashboardScannedChartList,
    fetchUnitGalleryState,
    unitGallery,
    setUnitGallery,
    fetchVehicleGallery,
    onFetchUnitGalleryStateReceived,
  };
}
