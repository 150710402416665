import { FC } from "react";
import { Descriptions, Modal, Typography } from "antd";
import { AuthRepository } from "../../../../../features/auth/data/repository/Auth";
import {
  faEnvelope,
  faUser,
  faAt,
  faPersonThroughWindow,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppUser } from "../../../../../app/hook/User";

type Props = {
  onClose: () => void;
};
export const DashboardLayoutUserModal: FC<Props> = ({ onClose }) => {
  const { customerUser: user } = useAppUser();

  return (
    <Modal
      open={true}
      onCancel={onClose}
      okText={"Cerrar Sesión"}
      okButtonProps={{
        icon: <FontAwesomeIcon icon={faPersonThroughWindow} />,
        onClick: () => AuthRepository.logout(),
      }}
    >
      <div className={"flex flex-col"}>
        <div className={"flex flex-col"}>
          <Typography.Title className={"p-0 m-0"} level={5}>
            {user?.name + " " + user?.lastname}
          </Typography.Title>
        </div>
        <Descriptions
          size={"small"}
          colon={false}
          layout={"vertical"}
          column={{
            xs: 1,
            lg: 1,
            md: 1,
            xxl: 1,
            sm: 1,
            xl: 1,
          }}
        >
          <Descriptions.Item
            span={2}
            label={
              <span>
                <FontAwesomeIcon icon={faAt} /> Nombre de Usuario
              </span>
            }
          >
            {user?.username}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={
              <span>
                <FontAwesomeIcon icon={faUser} /> ID
              </span>
            }
          >
            {user?.id}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={
              <span>
                <FontAwesomeIcon icon={faUser} /> Nombre(s)
              </span>
            }
          >
            {user?.name}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={
              <span>
                <FontAwesomeIcon icon={faUser} /> Apellidos
              </span>
            }
          >
            {user?.lastname}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={
              <span>
                <FontAwesomeIcon icon={faEnvelope} /> Correo Electrónico
                Principal
              </span>
            }
          >
            {user?.corporateEmail}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <span>
                <FontAwesomeIcon icon={faEnvelope} /> Correo Electrónico
                Secundario
              </span>
            }
          >
            {user?.email || "No registrado"}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  );
};
