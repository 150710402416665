import { FC, useCallback, useEffect, useMemo } from "react";
import { ConfigProvider, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppTheme } from "../../../../../app/hook/Theme";
import { useDashboardAllowedRoutes } from "../hook/Routes";
import { DashboardMenuUseCase } from "../../domain/usecase/Menu";

export const DashboardLayoutMainMenu: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themes, isDarkThemeEnabled } = useAppTheme();
  const generatePathSegments = useCallback((path: string) => {
    const segments = path.split("/");
    const pathSegments = [];
    let currentSegment = "";
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (segment) {
        currentSegment += "/" + segment;
        pathSegments.push(currentSegment);
      }
    }
    return pathSegments;
  }, []);
  const pathSegments = useMemo(() => {
    return generatePathSegments(pathname);
  }, [pathname, generatePathSegments]);

  const { paths } = useDashboardAllowedRoutes();

  useEffect(() => {
    console.log(paths);
  }, [paths]);

  return (
    <ConfigProvider theme={themes.menuTheme}>
      <Menu
        theme={isDarkThemeEnabled ? "dark" : "light"}
        onClick={(info) => {
          if (info.key !== pathname) navigate(info.key);
        }}
        selectedKeys={pathSegments}
        mode="inline"
        items={DashboardMenuUseCase.getMenuItems(paths)}
      />
    </ConfigProvider>
  );
};
