import { useEffect } from "react";
import { useDashboardHeader } from "../../../core/layout/dashboard/presentation/hook/Header";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { ConnectedUsers } from "./components/ConnectedUsers";
import { OverviewConnectedGatewaysSection } from "./components/ConnectedGateways";
import { useComponentSize } from "../../../app/hook/Size";
import { useDashboardAllowedRoutes } from "../../../core/layout/dashboard/presentation/hook/Routes";
import { Card, Flex, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/domain/model/Store";
import { WorldMapChart } from "../../../core/presentation/component/charts/WorldMap";
import { MultipleGatewayMap } from "../../../core/presentation/component/GatewayMap";
import { OverviewUsersSection } from "./components/users";
import { OverviewFleetSection } from "./components/fleet";
import { OverviewDevicesSection } from "./components/devices";

export const OverviewPage = () => {
  const { setTitle } = useDashboardHeader();
  const { allowedRoutes } = useDashboardAllowedRoutes();

  useEffect(() => {
    setTitle("Dashboard");
  }, []);

  return (
    <ResponsiveContainer>
      <Flex vertical gap={"large"}>
        <OverviewUsersSection />
        <OverviewDevicesSection />
        <OverviewFleetSection />
        {allowedRoutes.connected_gateways && (
          <OverviewConnectedGatewaysSection />
        )}
        {allowedRoutes.connected_users && (
          <div key={"connected_users"} className="w-full">
            <Typography.Title level={4}>Usuarios en línea</Typography.Title>
            <ConnectedUsers />
          </div>
        )}

        {allowedRoutes.billing_account && (
          <div key={"billing_account"} className="w-full">
            <Typography.Title level={4}></Typography.Title>
          </div>
        )}
      </Flex>
    </ResponsiveContainer>
  );
};
