import { Col, Row, Typography, Flex, Card } from "antd";
import { FC } from "react";
import { TotalDevicesCountCard } from "./TotalDevices";
import { ActiveDevicesCounterCard } from "./ActiveDevices";

type Props = {};

export const OverviewDevicesSection: FC<Props> = ({}) => {
  return (
    <Flex vertical>
      <Typography.Title level={3}>Dispositivos</Typography.Title>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Card bordered={false}>
            <TotalDevicesCountCard count={24} loading={false} />
          </Card>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Card bordered={false}>
            <ActiveDevicesCounterCard count={24} loading={false} />
          </Card>
        </Col>
      </Row>
    </Flex>
  );
};
