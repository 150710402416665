import { RemoteSourceResponse } from "../../../../core/domain/type/Source";
import { BaseRemoteSource } from "../../../../core/domain/model/Remote";
import { Customer } from "../../domain/type/Customer";
import { ApiService } from "../../../../app/service/RemoteClient";
import { CustomerStyle } from "../../domain/type/CustomerStyle";

export class RemoteCustomerSource extends BaseRemoteSource {
  static async getCustomer(): Promise<RemoteSourceResponse<Customer>> {
    try {
      const customer = await ApiService.getWithAuth("/customer/get");
      this.checkResponseCredentials(customer);
      return customer.data as RemoteSourceResponse<Customer>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerStyle(): Promise<
    RemoteSourceResponse<CustomerStyle>
  > {
    try {
      const customer = await ApiService.getWithAuth("/customer_style/get");
      this.checkResponseCredentials(customer);
      return customer.data as RemoteSourceResponse<CustomerStyle>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerDomainByEmail(email: string): Promise<
    RemoteSourceResponse<{
      customer: Customer;
      style: CustomerStyle;
    }>
  > {
    try {
      const customerDomain = await ApiService.postWithAuth(
        "/customer/get_by_email",
        {
          email,
        }
      );
      this.checkResponseCredentials(customerDomain);
      return customerDomain.data as RemoteSourceResponse<{
        customer: Customer;
        style: CustomerStyle;
      }>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
