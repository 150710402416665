import { Statistic } from "antd";
import { FC } from "react";

type Props = {
  count: number;
  loading: boolean;
};

export const TotalDevicesCountCard: FC<Props> = ({ count, loading }) => (
  <Statistic title={"Total"} loading={loading} value={count} />
);
