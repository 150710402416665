import { FC, Fragment, useEffect, useMemo, useState } from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { cloneDeep } from "lodash";
import * as echarts from "echarts/core";
import { ChartProps } from "./Render";
import {
  CHART_MIN_HEIGHT_BY_TYPE,
  CHART_MIN_WIDTH_BY_TYPE,
} from "../../../../features/vehicle/domain/usecase/ChartLayout";
import { GaugeChartItemConfig } from "../../../domain/type/ChartConfig";

export const GaugeChart: FC<
  ChartProps<GaugeChartItemConfig, GaugeChartItemConfig>
> = ({ chart, data, width, height }) => {
  const [gauges, setGauges] = useState<Array<EChartsOption>>([]);
  const baseGauges = useMemo<Array<EChartsOption>>(() => {
    const cols = Math.floor(width / CHART_MIN_WIDTH_BY_TYPE.dashboard_gauge);
    const rows = Math.floor(height / CHART_MIN_HEIGHT_BY_TYPE.dashboard_gauge);
    const widthPerItem = width / Math.max(cols, 1);
    const heightPerItem = height / Math.max(rows, 1);
    const detailFontSize = 18 * Math.max(1, Math.round(widthPerItem / width));
    const axisLabelFontSize =
      12 * Math.max(1, Math.round(widthPerItem / width));
    return (
      chart.ChartDefinition.schema.settings.usage?.map((it) => {
        const {
          dependency_key,
          title,
          unit,
          min_value,
          max_value,
          angle_start,
          angle_end,
          split,
          ranges,
          gradient,
          pointer_color,
          split_line_color,
          split_line_thickness,
          title_color,
          value_precision,
        } = it;
        const series = [];

        let isVertical = angle_start - angle_end < 0;

        series.push({
          type: "gauge",
          name: dependency_key,
          radius: "100%",
          startAngle: angle_start ?? 0,
          endAngle: angle_end ?? 180,
          min: min_value ?? 0,
          max: max_value ?? 100,
          splitNumber: split ?? 5,
          extra: {
            title,
          },
          axisLine: {
            lineStyle: {
              color: gradient
                ? [
                    [
                      1,
                      new echarts.graphic.LinearGradient(
                        isVertical ? -1 : 0,
                        0,
                        isVertical ? 0 : 1,
                        isVertical ? 1 : 0,
                        ranges
                          .sort((a, b) => a.from - b.from)
                          .map((it2) => {
                            const { to, color } = it2;
                            const end =
                              (to - (min_value as unknown as number)) /
                              ((max_value as unknown as number) -
                                (min_value as unknown as number));

                            return {
                              offset: end,
                              color: color ?? "#000",
                            };
                          })
                      ),
                    ],
                  ]
                : ranges
                    .sort((a, b) => a.from - b.from)
                    .map((it2) => {
                      const { to, color } = it2;
                      const end =
                        (to - (min_value as unknown as number)) /
                        ((max_value as unknown as number) -
                          (min_value as unknown as number));

                      return [end, color ?? "#000"];
                    }) ?? [1, "#000"],
            },
          },
          axisLabel: {
            color: split_line_color ?? "#000",
            fontSize: axisLabelFontSize,
          },
          axisTick: {
            distance: 1,
            lineStyle: {
              color: split_line_color ?? "#000",
            },
          },
          splitLine: {
            lineStyle: {
              color: split_line_color ?? "#000",
              width: split_line_thickness ?? 1,
            },
            distance: 1,
          },
          detail: {
            valueAnimation: true,
            formatter: (value: any) => {
              const precision = value_precision ?? 2;
              let r = value;
              if (!!value.toFixed) {
                r = value.toFixed(precision);
              }
              return ` ${r} ${unit ?? ""}`;
            },
            fontSize: detailFontSize,
            color: title_color ?? "#000",
            fontWeight: "normal",
            zlevel: 50,
          },

          title: {
            color: title_color ?? "#000",
          },
          data: [],
          pointer: {
            itemStyle: {
              color: pointer_color ?? "#000",
              zIndex: 50,
            },
            icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
            showAbove: true,
          },
        });

        return {
          series,
          opts: {
            width: widthPerItem,
            height: heightPerItem,
          },
        };
      }) ?? []
    );
  }, [chart, width, height]);

  useEffect(() => {
    if (!!data) {
      const copy = cloneDeep(baseGauges);
      copy.forEach((it, idx) => {
        const series = copy[idx].series[0];
        series.data = [
          {
            value: data[series.name],
            name: series.extra?.title,
          },
        ];
      });

      setGauges(copy);
    }
  }, [data, baseGauges]);

  return (
    <Fragment>
      {gauges.map((it, idx) => (
        <ReactECharts
          key={`gc-${it.series[0].name}-${idx}`}
          option={it}
          lazyUpdate
          opts={it.opts}
        />
      ))}
    </Fragment>
  );
};
