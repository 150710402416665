import { GatewayActionRequest } from "../../../features/gateway/domain/type/GatewayActions";
import { RemoteGatewayActionSource } from "../source/RemoteGatewayAction";

export class GatewayActionsRepository {
  static async getGatewayActions(gatewayId: number) {
    return await RemoteGatewayActionSource.getGatewayActions(gatewayId);
  }

  static async requestAction(request: GatewayActionRequest) {
    return await RemoteGatewayActionSource.requestAction(request);
  }
}
