import { FC } from "react";
import { Gateway } from "../../../../gateway/domain/type/Gateway";
import { MultipleGatewayMap } from "../../../../../core/presentation/component/GatewayMap";

type Props = {
  gatewayList: Gateway[];
  selectedGateway?: Gateway | null;
};

export const OverviewTrackerMap: FC<Props> = ({
  gatewayList,
  selectedGateway,
}) => {
  return (
    <MultipleGatewayMap
      gatewayList={gatewayList}
      selectedGateway={selectedGateway}
    />
  );
};
