import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { DashboardAllowedRoutes } from "../../domain/type/AllowedRoutes";
import { DashboardPathsType } from "../../domain/type/Paths";
import { DashboardPathsUseCase } from "../../domain/usecase/Paths";

type Value = {
  paths: DashboardPathsType;
  allowedRoutes: DashboardAllowedRoutes;
  setAllowedRoutes: (newRoutes: DashboardAllowedRoutes) => void;
};

export const DashboardRoutesContext = createContext<Value>({
  paths: null!,
  allowedRoutes: null!!,
  setAllowedRoutes: null!!,
});

export const DashboardRoutesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [allowedRoutes, setAllowedRoutes] = useState<DashboardAllowedRoutes>(
    {}
  );
  const [dashboardPaths, setDashboardPaths] = useState<DashboardPathsType>({});

  useEffect(() => {
    const paths =
      DashboardPathsUseCase.getPathsFromAllowedRoutes(allowedRoutes);
    setDashboardPaths(paths);
  }, [allowedRoutes]);

  return (
    <DashboardRoutesContext.Provider
      value={{
        allowedRoutes,
        paths: dashboardPaths,
        setAllowedRoutes,
      }}
    >
      {children}
    </DashboardRoutesContext.Provider>
  );
};
