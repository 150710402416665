import { RemoteSourceResponse } from "../../domain/type/Source";
import { BaseRemoteSource } from "../../domain/model/Remote";
import {
  VehicleChart,
  VehicleChartSelection,
} from "../../../features/gateway/domain/type/VehicleChart";
import { ApiService } from "../../../app/service/RemoteClient";

export class RemoteVehicleChartSource extends BaseRemoteSource {
  static async getVehicleCharts(
    id: number,
    selection?: VehicleChartSelection
  ): Promise<RemoteSourceResponse<Array<VehicleChart>>> {
    try {
      const response = await ApiService.postWithAuth("/vehicle_chart/list", {
        vehicleId: id,
        selection,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<VehicleChart>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async getCustomerCharts(
    selection?: VehicleChartSelection
  ): Promise<RemoteSourceResponse<Array<VehicleChart>>> {
    try {
      const response = await ApiService.postWithAuth(
        "/vehicle_chart/list_by_customer",
        {
          selection,
        }
      );
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<VehicleChart>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }

  static async scanVehicleCharts(
    gatewayId: number,
    vehicleId: number
  ): Promise<RemoteSourceResponse<Array<VehicleChart>>> {
    try {
      const response = await ApiService.postWithAuth("/vehicle_chart/scan", {
        gatewayId,
        vehicleId,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse<Array<VehicleChart>>;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
