import { FC } from "react";
import { FullscreenToggle } from "../Fullscreen";

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
};

export const UnitsMapFullScreenControl: FC<Props> = ({ containerRef }) => {
  return (
    <div className={"leaflet-bottom leaflet-right"}>
      <div className="leaflet-control">
        <FullscreenToggle
          size="large"
          type="primary"
          containerRef={containerRef}
        />
      </div>
    </div>
  );
};
