import { AxiosError, AxiosResponse } from "axios";
import { LocalTokenUseCase } from "../../../app/domain/usecase/Token";

export class BaseRemoteSource {
  static parseError(e: any) {
    const error = e as AxiosError;
    const errorStatusCode = error.response?.status;
    if (errorStatusCode === 401) {
      if (window.location.pathname.includes("dashboard")) {
        window.location.href = "/";
        window.location.reload();
        return;
      }
    }
    const response = error.response?.data as
      | {
          ok?: boolean;
          message?: string;
        }
      | undefined;
    if (!!response) {
      if (response.message) {
        throw new Error(response.message);
      } else {
        console.log(response);
      }
    }
    throw error;
  }

  static checkResponseCredentials(response: AxiosResponse<any, any>) {
    const rt = response.headers["x-refreshtoken"];
    const at = response.headers["x-accesstoken"];
    if (!!rt) {
      LocalTokenUseCase.saveRefreshToken(rt);
    }
    if (!!at) {
      LocalTokenUseCase.saveAccessToken(at);
    }
  }
}
