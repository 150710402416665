import {
  FC,
  Fragment,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useOverviewViewModel } from "../ViewModel";
import {
  Avatar,
  Button,
  Card,
  List,
  Skeleton,
  Typography,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTruck } from "@fortawesome/free-solid-svg-icons";
import { ImageHOC } from "../../../../core/presentation/component/Image";
import { MultipleGatewayMap } from "../../../../core/presentation/component/GatewayMap";
import { WorldMapChart } from "../../../../core/presentation/component/charts/WorldMap";
import * as echarts from "echarts/core";
import worldMapData from "../../../../assets/json/world.json";
import peruMapData from "../../../../assets/json/peru.json";
import stringToColor from "string-to-color";
import { Gateway } from "../../../gateway/domain/type/Gateway";

export const OverviewConnectedGatewaysSection: FC = () => {
  const [showByRegion, setShowByRegion] = useState<string | null>(null);
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(null);
  const {
    fetchConnectedGateways,
    fetchConnectedGatewaysState,
    onFetchConnectedGatewaysStateReceived,
    connectedGateways,
  } = useOverviewViewModel();

  useEffect(() => {
    echarts.registerMap("world", worldMapData as any);
    echarts.registerMap("Peru", peruMapData as any);

    void fetchConnectedGateways();
  }, []);

  const worldWideCount = useMemo(() => {
    return [{ name: "Peru", value: connectedGateways.length }];
  }, [connectedGateways]);

  const localCount = useMemo(() => {
    return [{ name: "Lima", value: connectedGateways.length }];
  }, [connectedGateways]);

  const onRegionSelected = useCallback(
    (name: string) => {
      if (name === "Peru") {
        setShowByRegion(name);
      }
    },
    [setShowByRegion]
  );

  const onCloseByRegion = useCallback(() => {
    setShowByRegion(null);
  }, [setShowByRegion]);

  const onGatewaySelected = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const key = event.currentTarget.getAttribute("data-key");
      const gateway = connectedGateways.find((it) => it.key === key);
      if (!!gateway) setSelectedGateway(gateway);
    },
    [setSelectedGateway, connectedGateways]
  );

  const onCancelSelectedGateway = useCallback(() => {
    setSelectedGateway(null);
  }, [setSelectedGateway]);

  useEffect(() => {
    if (!!fetchConnectedGatewaysState && !fetchConnectedGatewaysState.loading) {
      if (fetchConnectedGatewaysState.hasError) {
        notification.error({
          message: "Error",
          description: fetchConnectedGatewaysState.error?.message,
        });
      }
      onFetchConnectedGatewaysStateReceived();
    }
  }, [fetchConnectedGatewaysState]);

  return (
    <div key={"connected_gateways"} className="w-full">
      <Typography.Title level={4}>Vehículos en línea</Typography.Title>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-3 gap-2">
          <div className="w-full h-96 col-span-2 flex flex-col gap-2">
            <Typography.Text
              style={{ padding: 0, margin: 0 }}
              type="secondary"
              className="leading-tight"
            >
              Mapa en tiempo real
            </Typography.Text>
            <Card className="w-full h-full p-0 m-0 overflow-hidden">
              <MultipleGatewayMap
                gatewayList={connectedGateways}
                selectedGateway={selectedGateway}
                onCloseSelectedGateway={onCancelSelectedGateway}
              />
            </Card>
          </div>
          <div className="col-span-1 h-full border-0 border-b border-solid border-neutral-300">
            <Skeleton loading={fetchConnectedGatewaysState?.loading === true}>
              <div className="flex flex-col gap-2">
                <Typography.Text
                  style={{ padding: 0, margin: 0 }}
                  type="secondary"
                  className="leading-tight"
                >
                  {connectedGateways.length + " "} en línea
                </Typography.Text>
                <Card size="small">
                  <List
                    size="small"
                    dataSource={connectedGateways}
                    renderItem={(item) => {
                      return (
                        <List.Item
                          data-key={item.key}
                          onClick={onGatewaySelected}
                        >
                          <List.Item.Meta
                            avatar={
                              <Avatar
                                size={"large"}
                                icon={
                                  <ImageHOC
                                    imgProps={{
                                      src: item.Vehicle?.photo,
                                      className:
                                        "w-full h-full aspect-square overflow-hidden object-cover",
                                    }}
                                    errorComponent={
                                      <FontAwesomeIcon icon={faTruck} />
                                    }
                                  />
                                }
                              />
                            }
                            title={
                              <Fragment>
                                <span
                                  style={{
                                    backgroundColor: stringToColor(item.key),
                                  }}
                                  className="w-2 h-2 rounded-full inline-block mr-2"
                                />
                                {item.Vehicle?.name}
                              </Fragment>
                            }
                            description={item.info.ping_acme}
                          />
                        </List.Item>
                      );
                    }}
                  />
                </Card>
              </div>
            </Skeleton>
          </div>
        </div>
        <div className="p-0 m-0 overflow-hidden rounded-md relative">
          {showByRegion === null ? (
            <WorldMapChart
              schema={{
                map: "world",
                max: Math.min(connectedGateways.length, 100),
                min: 0,
                title: "Vehículos en línea",
                subtitle: "Vehículos en línea",
                unit: "vehículos",
              }}
              data={worldWideCount}
              onSelected={onRegionSelected}
            />
          ) : (
            <Fragment>
              <Button
                className="absolute left-0 top-0 m-2 z-50"
                icon={<FontAwesomeIcon icon={faClose} />}
                onClick={onCloseByRegion}
              />
              <WorldMapChart
                schema={{
                  map: "Peru",
                  max: Math.min(connectedGateways.length, 100),
                  min: 0,
                  title: "Vehículos en línea",
                  subtitle: "Vehículos en línea",
                  unit: "vehículos",
                }}
                data={localCount}
              />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
