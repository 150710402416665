import { FC, MouseEventHandler, useCallback, useMemo } from "react";
import { Avatar, Button, Switch, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { useAppTheme } from "../../../../../app/hook/Theme";
import { CustomerLogo } from "../../../../presentation/component/CustomerLogo";
import { useAppCustomer } from "../../../../../app/hook/Customer";
import { useAppUser } from "../../../../../app/hook/User";

type Props = {
  onActionClick: (action: string) => void;
};

export const DashboardLayoutHeaderLarge: FC<Props> = ({ onActionClick }) => {
  const { isDarkThemeEnabled, switchDarkTheme } = useAppTheme();
  const { customer } = useAppCustomer();
  const { customerUser } = useAppUser();
  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      onActionClick(e.currentTarget.dataset["key"] as string);
    },
    [onActionClick]
  );

  const avatarName = useMemo(() => {
    if (!!customerUser?.username) {
      return customerUser?.username;
    } else if (!!customerUser?.name) {
      return `${customerUser?.name} ${customerUser?.lastname}`;
    } else {
      return "User";
    }
  }, [customerUser]);

  return (
    <div
      className={
        "w-full h-full flex flex-row gap-8 place-items-center place-content-start"
      }
      style={{
        zIndex: 9999,
      }}
    >
      <div className="h-full overflow-hidden my-auto flex flex-col items-center py-2.5">
        {isDarkThemeEnabled ? (
          <span className="text-white my-auto font-quicksand text-lg font-bold">
            {customer?.businessName}
          </span>
        ) : (
          <CustomerLogo />
        )}
      </div>

      <div
        className={
          "flex flex-row place-content-center place-items-center ml-auto gap-1"
        }
      >
        <Tag
          className="flex flex-row gap-1 rounded-full py-1 place-content-center place-items-center font-quicksand font-semibold cursor-pointer"
          onClick={onClick}
          data-key={"user"}
        >
          <Avatar
            className="flex p-0 items-center justify-center m-0 bg-primary"
            size={"small"}
            icon={avatarName[0].toUpperCase()}
          />
          {avatarName}
        </Tag>
        <Switch
          checked={isDarkThemeEnabled}
          checkedChildren={<FontAwesomeIcon icon={faSun} />}
          unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
          onChange={switchDarkTheme}
        />
        <Button
          shape="circle"
          type={"text"}
          icon={<FontAwesomeIcon icon={faGear} />}
        />
      </div>
    </div>
  );
};
