import { FC, Key, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Tree,
  TreeDataNode,
  TreeProps,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faRefresh,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { ResponsiveContainer } from "../../../core/presentation/component/Container";
import { ContentHeader } from "../../../core/presentation/component/ContentHeader";
import { CustomBreadCrumb } from "../../../core/presentation/component/Breadcrumb";
import { AppLoader } from "../../../core/presentation/component/AppLoader";
import { useFleetTrackingPageViewModel } from "./ViewModel";
import { Gateway } from "../../gateway/domain/type/Gateway";
import { UnitsMap } from "../../../core/presentation/component/units_map/UnitsMap";

export const FleetTrackingPage: FC = () => {
  const [treeCheckedKeys, setTreeCheckedKeys] = useState<string[]>([]);
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(null);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const {
    fetchGatewayList,
    fetchGatewayListState,
    gateways,
    gatewayGroups,
    onFetchGatewayListStateReceived,
  } = useFleetTrackingPageViewModel();

  useEffect(() => {
    if (!!fetchGatewayListState && !fetchGatewayListState.loading) {
      if (fetchGatewayListState.error) {
        notification.error({
          message: "Error al cargar la lista de gateways",
          description: fetchGatewayListState.error.message,
        });
      }
      onFetchGatewayListStateReceived();
    }
  }, [fetchGatewayListState]);

  useEffect(() => {
    fetchGatewayList();
  }, []);

  const treeData = useMemo<TreeDataNode[]>(() => {
    const tree: TreeDataNode[] = [];
    gatewayGroups.forEach((group) => {
      const nodes: TreeDataNode[] = [];
      group.gateways.forEach((gateway) => {
        nodes.push({
          key: gateway.key,
          title: gateway.key,
          icon: <FontAwesomeIcon icon={faServer} />,
        });
      });
      tree.push({
        key: `vehicle-${group.vehicle.id}`,
        title: group.vehicle.name,
        icon: <FontAwesomeIcon icon={faLocation} />,
        children: nodes,
      });
    });
    return tree;
  }, [gatewayGroups]);

  const onTreeCheck: TreeProps["onCheck"] = useCallback((checkedKeys: any) => {
    setTreeCheckedKeys(checkedKeys);
  }, []);

  const selectedGateways = useMemo(() => {
    const gatewayKeys = treeCheckedKeys.filter(
      (key) => !key.startsWith("vehicle-")
    );
    return gateways.filter((gateway) => gatewayKeys.includes(gateway.key));
  }, [treeCheckedKeys, gateways]);

  const onGatewaySelected = useCallback(
    (gateway: Gateway | null) => {
      setSelectedGateway(gateway ?? null);
    },
    [setSelectedGateway]
  );

  useEffect(() => {
    setExpandedKeys(treeData.map((node) => node.key));
  }, [treeData]);

  return (
    <ResponsiveContainer className={"w-full h-fit overflow-hidden"}>
      <AppLoader loading={fetchGatewayListState?.loading ?? false} />
      <CustomBreadCrumb
        items={[
          {
            pathname: "/dashboard/gateways",
            title: "Localización de Flota",
            active: true,
            icon: <FontAwesomeIcon icon={faLocation} />,
          },
        ]}
      />

      <ContentHeader
        title={"Localización de Flota"}
        subtitle={`Visualiza la localización de tus unidades`}
        extra={
          <Button.Group>
            <Button
              onClick={fetchGatewayList}
              type={"primary"}
              icon={<FontAwesomeIcon icon={faRefresh} />}
            >
              Actualizar
            </Button>
          </Button.Group>
        }
      />
      <div className="w-full flex flex-col gap-2">
        <Card size="small" bordered={false}>
          <Tree
            checkable
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
            treeData={treeData}
            onCheck={onTreeCheck}
            checkedKeys={treeCheckedKeys}
          />
        </Card>
        <div className="w-full h-screen relative">
          <UnitsMap
            gatewayList={selectedGateways}
            onGatewaySelected={onGatewaySelected}
            selectedGateway={selectedGateway}
          />
        </div>
      </div>
    </ResponsiveContainer>
  );
};
