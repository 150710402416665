import { RemoteSourceResponse } from "../../domain/type/Source";
import { BaseRemoteSource } from "../../domain/model/Remote";
import { ApiService } from "../../../app/service/RemoteClient";
import { UnitAsset } from "../../domain/type/UnitAsset";

export class RemoteUnityAssetSource extends BaseRemoteSource {
  static async getUnityAsset(
    vehicleId: number,
    key: string
  ): Promise<RemoteSourceResponse<UnitAsset>> {
    try {
      const response = await ApiService.postWithAuth("/unit_asset/get", {
        vehicleId,
        key,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
  static async getUnityGallery(
    vehicleId: number
  ): Promise<RemoteSourceResponse<UnitAsset[]>> {
    try {
      const response = await ApiService.postWithAuth("/unit_asset/gallery", {
        vehicleId,
      });
      this.checkResponseCredentials(response);
      return response.data as RemoteSourceResponse;
    } catch (e) {
      throw this.parseError(e);
    }
  }
}
