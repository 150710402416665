import { Statistic } from "antd";
import { FC } from "react";

type Props = {
  count: number;
  loading: boolean;
};

export const ActiveUsersCounterCard: FC<Props> = ({ count, loading }) => (
  <Statistic title={"Activos"} value={count} loading={loading} />
);
