import { FC, Fragment, MouseEventHandler, useCallback, useMemo } from "react";
import { Gateway } from "../../../../gateway/domain/type/Gateway";
import { Avatar, List } from "antd";
import VirtualizedList, { ListProps } from "rc-virtual-list";
import { ImageHOC } from "../../../../../core/presentation/component/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import stringToColor from "string-to-color";
import { RenderFunc } from "rc-virtual-list/lib/interface";
import { useComponentSize } from "../../../../../app/hook/Size";

type EncapsulatedGatewayItem = {
  isSelected: boolean;
  gateway: Gateway;
};

type Props = {
  gatewayList: Gateway[];
  onSelectedGateway: (gateway: Gateway) => void;
  selectedGateway?: Gateway | null;
};

export const OverviewFleetList: FC<Props> = ({
  gatewayList,
  onSelectedGateway,
  selectedGateway,
}) => {
  const { ref, height } = useComponentSize();
  const data = useMemo<Array<EncapsulatedGatewayItem>>(
    () =>
      gatewayList.map((it) => ({
        gateway: it,
        isSelected: selectedGateway?.key === it.key,
      })),
    [gatewayList, selectedGateway]
  );

  const onListClicked = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      let currentElement: HTMLElement | null = e.target as HTMLElement;
      while (
        !!currentElement &&
        currentElement &&
        currentElement.dataset.role !== "item"
      ) {
        currentElement = currentElement.parentElement;
      }
      if (!currentElement) return;
      const key = currentElement.dataset.key;
      if (!key) return;
      const gateway = gatewayList.find((it) => it.key === key);
      if (!gateway) return;
      onSelectedGateway(gateway);
    },
    [gatewayList, onSelectedGateway]
  );

  return (
    <div className="h-full w-full" ref={ref}>
      <List size="small" className="relative">
        <VirtualizedList
          itemKey={"gw"}
          height={height}
          itemHeight={150}
          data={data}
          onClick={onListClicked}
        >
          {Item}
        </VirtualizedList>
      </List>
    </div>
  );
};

type GatewayItemRenderProps = {
  gateway: Gateway;
};

const GatewayItemRender: FC<GatewayItemRenderProps> = ({ gateway }) => {
  return (
    <List.Item.Meta
      avatar={
        <Avatar
          size={"large"}
          icon={
            <ImageHOC
              imgProps={{
                src: gateway.Vehicle?.photo,
                className:
                  "w-full h-full aspect-square overflow-hidden object-cover",
              }}
              errorComponent={<FontAwesomeIcon icon={faQuestion} />}
            />
          }
        />
      }
      title={
        <Fragment>
          <span
            style={{
              backgroundColor: stringToColor(gateway.key),
            }}
            className="w-2 h-2 rounded-full inline-block mr-2"
          />
          {gateway.Vehicle?.name}
        </Fragment>
      }
      description={gateway.info?.ping_acme}
    />
  );
};

const Item: RenderFunc<EncapsulatedGatewayItem> = (item) => {
  return (
    <List.Item
      data-key={item.gateway.key}
      data-role={"item"}
      key={item.gateway.key}
      className={
        item.isSelected
          ? "outline-primary outline-solid outline-1 sticky top-0 inset-0 left-0 right-0 z-50"
          : undefined
      }
    >
      <GatewayItemRender gateway={item.gateway} />
    </List.Item>
  );
};
