import { useState } from "react";
import { AppTaskState } from "../../../app/domain/type/State";
import { Vehicle } from "../../vehicle/domain/type/Vehicle";
import { AppTaskStateG } from "../../../app/domain/model/State";
import { Gateway } from "../../gateway/domain/type/Gateway";
import { GatewayRepository } from "../../gateway/data/repository/Gateway";
import { isNil } from "lodash";

type GatewayGroup = Array<{
  vehicle: Vehicle;
  gateways: Gateway[];
}>;

export function useFleetTrackingPageViewModel() {
  const [fetchGatewayListState, setFetchGatewayListState] =
    useState<AppTaskState<boolean> | null>(null);
  const [gateways, setGateways] = useState<Gateway[]>([]);
  const [gatewayGroups, setGatewayGroups] = useState<GatewayGroup>([]);

  async function fetchGatewayList() {
    if (fetchGatewayListState?.loading) {
      return;
    }
    try {
      const gatewayList = await GatewayRepository.getAllGateways();
      if (gatewayList.ok) {
        const group: GatewayGroup = [];
        gatewayList.data!!.forEach((gateway) => {
          if (!isNil(gateway.vehicleId)) {
            const index = group.findIndex(
              (g) => g.vehicle.id === gateway.vehicleId
            );
            if (index === -1) {
              group.push({
                vehicle: gateway.Vehicle!!,
                gateways: [gateway],
              });
            } else {
              group[index].gateways.push(gateway);
            }
          }
        });
        setGateways(gatewayList.data!!);
        setGatewayGroups(group);
        setFetchGatewayListState(AppTaskStateG.success(true));
      } else {
        setFetchGatewayListState(
          AppTaskStateG.error(new Error(gatewayList.message))
        );
      }
    } catch (error: any) {
      setFetchGatewayListState(AppTaskStateG.error(error));
    }
  }

  const onFetchGatewayListStateReceived = () => {
    setFetchGatewayListState(null);
  };

  return {
    fetchGatewayList,
    fetchGatewayListState,
    onFetchGatewayListStateReceived,
    gateways,
    gatewayGroups,
  };
}
