import { Layout } from "react-grid-layout";
import { VehicleChart } from "../../../gateway/domain/type/VehicleChart";

const DEFAULT_COL_WIDTH = 50;
const DEFAULT_COL_HEIGHT = 50;
export const CHART_MIN_WIDTH_BY_TYPE: { [key: string]: number } = {
  dashboard_linear: 350,
  dashboard_area: 350,
  dashboard_gauge: 300,
  dashboard_level: 300,
};
export const CHART_MIN_HEIGHT_BY_TYPE: { [key: string]: number } = {
  dashboard_linear: 250,
  dashboard_area: 250,
  dashboard_gauge: 300,
  dashboard_level: 300,
};

export class ChartLayoutUseCase {
  static generateLayout(availableSpace: number, schemas: VehicleChart[]) {
    let cols = Math.floor(availableSpace / DEFAULT_COL_WIDTH);
    cols = cols % 2 === 0 ? cols : cols + 1;
    const colWidth = Math.floor(availableSpace / cols);
    const rowHeight = DEFAULT_COL_HEIGHT;
    const layout: Layout[] = [];
    let currentX = 0,
      currentY = 0;
    schemas.forEach((s, i) => {
      const currentChart = s.ChartDefinition;
      const currentSingleNeededCols = Math.max(
        Math.round(
          CHART_MIN_WIDTH_BY_TYPE[currentChart.ChartSchema.type] / colWidth
        ),
        1
      );
      const currentSingleNeededRows = Math.max(
        Math.round(
          CHART_MIN_HEIGHT_BY_TYPE[currentChart.ChartSchema.type] / rowHeight
        ),
        1
      );
      const totalNeededCols = s.ChartDefinition.ChartSchema.individualGraphs
        ? currentSingleNeededCols * currentChart.schema.settings.usage.length
        : currentSingleNeededCols;
      let hCols = 0,
        vCols = 0;
      if (totalNeededCols > cols) {
        vCols = Math.ceil(totalNeededCols / cols) * currentSingleNeededRows;
        hCols = currentSingleNeededCols;
      } else {
        hCols = totalNeededCols;
        vCols = currentSingleNeededRows;
      }

      if (currentX + hCols > cols) {
        const inSameRow = layout.filter((it) => it.y === currentY);
        const eachSize = (cols - currentX) / inSameRow.length;
        inSameRow.forEach((it) => {
          const index = layout.findIndex((l) => l.i === it.i);
          layout[index].w = layout[index].w + eachSize;
          if (layout[index].x !== 0) {
            layout[index].x = layout[index].x + eachSize;
          }
        });
        currentX = 0;
        currentY += vCols;
      }

      layout.push({
        i: "dashboardchart_" + i,
        x: currentX,
        y: currentY,
        w: hCols,
        h: vCols,
        minW: hCols,
        minH: vCols,
      });
      currentX += hCols;
      if (currentX >= cols) {
        currentX = 0;
        currentY += vCols;
      }
    });

    return {
      layout,
      cols,
      rowHeight,
      colWidth,
    };
  }
}
