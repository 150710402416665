import { Navigate, RouteObject } from "react-router-dom";
import { RouteGenerator } from "../../../../domain/model/RouteGenerator";
import { DashboardLayout } from "../../presentation/Layout";
import { AuthRepository } from "../../../../../features/auth/data/repository/Auth";
import { OverviewPage } from "../../../../../features/overview/presentation/Page";
import { DashboardPaths } from "./Paths";
import { MainPaths } from "../../../main/domain/implementation/Paths";
import { VehicleListPage } from "../../../../../features/vehicle_list/presentation/Page";
import { GatewayListPage } from "../../../../../features/gateway_list/presentation/Page";
import { UserListPage } from "../../../../../features/user_list/presentation/Page";
import { CustomerPage } from "../../../../../features/customer/presentation/Page";
import { VehiclePage } from "../../../../../features/vehicle/presentation/Page";
import { ParamTypeValidatorLoader } from "../../../../util/Params";
import { CustomerUserPage } from "../../../../../features/customer_user/presentation/Page";
import { GatewayPage } from "../../../../../features/gateway/presentation/Page";
import { FleetTrackingPage } from "../../../../../features/fleet_tracking/presentation/Page";
import { FleetDashboardPage } from "../../../../../features/fleet_dashboard/presentation/Page";

export class DashboardRouteGenerator extends RouteGenerator {
  override build(): RouteObject[] {
    return [
      {
        path: DashboardPaths.dashboard,
        element: <DashboardLayout />,
        loader: async () => AuthRepository.getSession(),
        errorElement: <Navigate to={MainPaths.auth} />,
        children: [
          {
            path: DashboardPaths.overview,
            element: <OverviewPage />,
          },

          {
            path: DashboardPaths.fleet_list,
            element: <VehicleListPage />,
          },
          {
            path: DashboardPaths.gateway_list,
            element: <GatewayListPage />,
          },
          {
            path: DashboardPaths.fleet_tracking,
            element: <FleetTrackingPage />,
          },
          {
            path: DashboardPaths.fleet_dashboard,
            element: <FleetDashboardPage />,
          },
          {
            path: DashboardPaths.user_list,
            element: <UserListPage />,
          },
          {
            path: DashboardPaths.customer,
            element: <CustomerPage />,
          },
          {
            path: DashboardPaths.unit_p_id,
            element: <VehiclePage />,
            loader: ParamTypeValidatorLoader("id"),
          },
          {
            path: DashboardPaths.user_p_id,
            element: <CustomerUserPage />,
            loader: ParamTypeValidatorLoader("id"),
          },
          {
            path: DashboardPaths.gateway_p_id,
            element: <GatewayPage />,
            loader: ParamTypeValidatorLoader("id"),
          },
        ],
      },
    ];
  }
}
